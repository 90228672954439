import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { readRemoteFile } from 'react-papaparse';
import { DataGrid } from '@material-ui/data-grid';
import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import CellExpander from './CellExpander';
import { useStyles } from './DataGridViewer.styles';

export const DataGridViewerPropTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

function DataGridViewer({ file }) {
  const classes = useStyles();
  const [grid, setGrid] = useState({ columns: [], rows: [] });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (!file || !file.url) return;
    setLoading(true);
    readRemoteFile(file.url, {
      header: true,
      complete: (results) => {
        const { data } = results;
        if (!data || !data.length) return;
        const first = data[0];
        const keys = Object.keys(first);
        const columns = keys.map((o) =>
          o === ''
            ? { field: 'id', headerName: '-', width: 50, renderCell: CellExpander }
            : { field: o, headerName: o, width: 220, renderCell: CellExpander }
        );
        const rows = data.map((o, index) => ({ ...o, id: index }));
        setGrid({ columns, rows });
        setLoading(false);
      }
    });
  }, [file]);

  if (!file) return null;
  return (
    <div style={{ flex: 1, width: '100%', height: '100%' }}>
      <div className={classes.grid}>
        <div className={classes.gridToolbar}>
          {loading && <CircularProgress />}
          <Button
            disabled={loading}
            type="submit"
            variant="contained"
            color="secondary"
            className={classes.gridButton}
            onClick={() => {
              window.open(file.url, '_blank');
            }}
          >
            Download file
          </Button>
        </div>
        <DataGrid rows={grid.rows} rowsPerPageOptions={[25, 50, 100, 500]} autoPageSize columns={grid.columns} />
      </div>
    </div>
  );
}
DataGridViewer.propTypes = DataGridViewerPropTypes;

DataGridViewer.defaultProps = {};

export default DataGridViewer;
