import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles, addToCSV } from './HPUtils';

export const HPTableActivationsPropTypes = {
  activations: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

function populateTable({ activations, setRows }) {
  const activationsArr = activations.split(',');

  const options = [];
  activationsArr.forEach((item, index) => {
    options.push({
      id: `NNItem-${index}`,
      activations: item
    });
  });
  setRows(options);
}

function HPTableActivations({ activations, onChange }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleChange = (prop, index) => (event) => {
    const { value } = event.target;
    onChange('activations', addToCSV(activations, index, value));
  };

  const handleClick = () => {
    const defaultValue = 'relu';
    onChange('activations', `${activations},${defaultValue}`);
  };

  const handleDelete = (index) => () => {
    const activationsArr = activations.split(',');
    activationsArr.splice(index, 1);
    onChange('activations', activationsArr.join(','));
  };

  useEffect(() => {
    populateTable({ activations, setRows });
  }, [activations, setRows]);

  return (
    <div data-testid="HPTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Activations</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell style={{ width: 150 }}>
                  <Select
                    value={o.activations}
                    fullWidth
                    labelId="initializer-select-label"
                    id="initializer-select"
                    onChange={handleChange('activations', index)}
                  >
                    <MenuItem value="relu">Rectified Linear Unit (ReLU)</MenuItem>
                    <MenuItem value="sigmoid">Sigmoid (Logistic)</MenuItem>
                    <MenuItem value="tanh">Sigmoid (Tanh)</MenuItem>
                  </Select>
                </TableCell>

                <TableCell style={{ width: 20 }}>
                  <IconButton
                    size="small"
                    aria-label="remove"
                    disabled={rows.length <= 1}
                    onClick={handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
HPTableActivations.propTypes = HPTableActivationsPropTypes;

HPTableActivations.defaultProps = {};

export default HPTableActivations;
