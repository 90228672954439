/* eslint-disable no-extra-boolean-cast */
/* eslint-disable no-nested-ternary */
import React, { useEffect, useMemo } from 'react';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import HelpIcon from '@material-ui/icons/Help';
import SettingsIcon from '@material-ui/icons/Settings';
import CollectionsIcon from '@material-ui/icons/Collections';
import CircularProgress from '@material-ui/core/CircularProgress';
import LegendPanel from 'components/WorkflowCanvas/LegendPanel';
import NodeSetup from 'components/Nodes';
import { useDispatch, useSelector } from 'react-redux';
import { updateNode, deleteNode } from 'store/modules/workflows';
import SectionDrawer from 'components/Common/SectionDrawer';
import NotSupported from 'components/InteractiveVisualizations/EmptyPages/NotSupported';
import InteractiveVisualizations from '../WorkflowVisualizations/Visualizations/InteractiveVisualizations';
import { useStyles } from './NodeSidePanel.styles';
import { useNodeSidePanelContext } from '.';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {children}
    </div>
  );
}

const NodePanel = () => {
  const {
    selectedNode,
    handleCancelUpdateNode,
    handleOpenLog,
    handleDownloadOutputFiles,
    handleCloseTab,
    workflow,
    open,
    onClose,
    fullScreen,
    setFullScreen
  } = useNodeSidePanelContext();
  const visualizations = useSelector((state) => state.workflows.visualizations.files);
  const charts = useSelector((state) => state.workflows.visualizations.charts);
  const isVisualizationsLoading = useSelector((state) => state.workflows.visualizations.loading);
  const hasVisualizations = visualizations.length || charts.length;

  //prevent rerender of all comp when fullscreen changes
  const InteractiveVisualizationsComp = useMemo(() => {
    return <InteractiveVisualizations charts={charts || []} staticVisualizations={visualizations || []} />;
  }, [charts, visualizations]);
  const classes = useStyles();
  const dispatch = useDispatch();

  const [tabPanelValue, setTabPanelValue] = React.useState(0);

  const handleTabPanelChange = (event, newValue) => {
    setTabPanelValue(newValue);
  };

  const handleUpdateNode = (node) => {
    const properties = { ...selectedNode, data: node };
    dispatch(updateNode({ workflowId: workflow.id, nodeId: selectedNode.id, properties })).then(() => {
      handleCancelUpdateNode();
    });
  };

  const handleDeleteNode = (id) => {
    if (!id) return;
    dispatch(deleteNode({ id }));
    handleCloseTab(null);
  };

  useEffect(() => {
    if (tabPanelValue !== 0) setFullScreen(false);
  }, [tabPanelValue]);

  return (
    <SectionDrawer
      open={open}
      fullScreen={fullScreen}
      setFullScreen={setFullScreen}
      onClose={onClose}
      showExpandButton={hasVisualizations && tabPanelValue === 0}
    >
      <div className={classes.sidePanel} data-testid="NodeSidePanel">
        <div className={classes.tabs} data-testid="panelTabs">
          <Tabs
            // variant={fullScreen ? '' : 'fullWidth'}
            variant="fullWidth"
            indicatorColor="primary"
            value={tabPanelValue}
            onChange={handleTabPanelChange}
            aria-label="legend panel tabs for nodes"
            // centered={!!fullScreen}
            action={(ref) => {
              //tricky way to notify on ffull screen update and rerender
              if (ref) setTimeout(() => ref.updateIndicator(), 310);
            }}
          >
            <Tab label="Visualizations" icon={<CollectionsIcon />} />
            <Tab label="Settings" icon={<SettingsIcon />} />
            <Tab label="Legend" icon={<HelpIcon />} />
          </Tabs>
        </div>

        <TabPanel value={tabPanelValue} index={0}>
          {isVisualizationsLoading ? (
            <div
              style={{
                width: '100%',
                height: '100vh',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
              }}
            >
              <CircularProgress />
            </div>
          ) : !!hasVisualizations ? (
            <>{InteractiveVisualizationsComp}</>
          ) : (
            <NotSupported message="This node has no visualizations" />
          )}
        </TabPanel>

        <TabPanel value={tabPanelValue} index={1}>
          <NodeSetup
            workflow={workflow}
            element={selectedNode}
            inline
            onCancel={handleCancelUpdateNode}
            onSave={handleUpdateNode}
            onDelete={handleDeleteNode}
            onShowLogs={handleOpenLog}
            onDownloadOutputFiles={handleDownloadOutputFiles}
          />
        </TabPanel>
        <TabPanel value={tabPanelValue} index={2}>
          <LegendPanel selectedNode={selectedNode} onCancel={handleCloseTab} />
        </TabPanel>
      </div>
    </SectionDrawer>
  );
};

export default NodePanel;
