import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles, addToCSV, getCSV } from './HPUtils';

export const HPTableRegularizationPropTypes = {
  regularizationMethods: PropTypes.string.isRequired,
  regularizationParams: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

function populateTable({ regularizationMethods, regularizationParams, setRows }) {
  const regularizationMethodsArr = (regularizationMethods || '').split(',');
  const regularizationParamsArr = (regularizationParams || '').split(',');

  const options = [];
  regularizationMethodsArr.forEach((item, index) => {
    options.push({
      id: `NNItem-${index}`,
      regularizationMethods: item,
      regularizationParams: regularizationParamsArr[index] || ''
    });
  });
  setRows(options);
}

function HPTableRegularization({ regularizationMethods, regularizationParams, onChange, multipleRows = true }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);
  const percentageInputProps = { min: 5, max: 50, step: 1 };
  const percentagePlaceholder = '20';
  const decimalInputProps = { min: 0.000001, max: 0.1, step: 0.001 };
  const decimalPlaceholder = '0.000001';

  const handleChange = (prop, index, method) => (event) => {
    let { value } = event.target;
    const allowedProps = {
      regularization_methods: regularizationMethods,
      regularization_params: regularizationParams
    };

    // Only integers allowed in dropout
    if (prop === 'regularization_params' && method == 'dropout') {
      value = parseInt(`${value}`.replace(/[^0-9]/g, ''));
    }

    if (prop === 'regularization_params' && method == 'none') {
      value = '0';
    }

    // Change default value when changing method
    if (prop === 'regularization_methods') {
      const paramValue = getCSV(regularizationParams, index);
      const defaultValues = {
        none: '0',
        dropout: percentagePlaceholder,
        l2: decimalPlaceholder
      };
      const newParam = defaultValues[value];
      onChange('regularization_params', addToCSV(regularizationParams, index, newParam));
    }

    onChange(prop, addToCSV(allowedProps[prop], index, value));
  };

  const handleClick = () => {
    const defaultMethod = 'l2';
    const defaultParam = '0.000001';
    onChange('regularization_methods', `${regularizationMethods},${defaultMethod}`);
    onChange('regularization_params', `${regularizationParams},${defaultParam}`);
  };

  const handleDelete = (index) => () => {
    const methodsList = regularizationMethods.split(',');
    const paramsList = regularizationParams.split(',');
    methodsList.splice(index, 1);
    paramsList.splice(index, 1);
    onChange('regularization_methods', methodsList.join(','));
    onChange('regularization_params', paramsList.join(','));
  };

  useEffect(() => {
    populateTable({ regularizationMethods, regularizationParams, setRows });
  }, [regularizationMethods, regularizationParams, setRows]);

  const methodTitle = multipleRows ? 'Regularization Methods' : 'Regularization Method';
  const paramTitle = multipleRows ? 'Regularization Params' : 'Regularization Param';
  return (
    <div data-testid="HPTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>{methodTitle}</TableCell>
              <TableCell>{paramTitle}</TableCell>
              {multipleRows && <TableCell></TableCell>}
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell style={{ width: 150 }}>
                  <Select
                    value={o.regularizationMethods}
                    fullWidth
                    labelId="activation-select-label"
                    id="activation-select"
                    onChange={handleChange('regularization_methods', index)}
                  >
                    <MenuItem value="l2">L2 Weight Regularization</MenuItem>
                    <MenuItem value="dropout">Dropout Regularization</MenuItem>
                    <MenuItem value="none">No Weight Regularization</MenuItem>
                  </Select>
                </TableCell>

                <TableCell>
                  <TextField
                    value={o.regularizationParams}
                    InputProps={{
                      inputProps: o.regularizationMethods == 'dropout' ? percentageInputProps : decimalInputProps
                    }}
                    placeholder={o.regularizationMethods == 'dropout' ? percentagePlaceholder : decimalPlaceholder}
                    type="number"
                    onChange={handleChange('regularization_params', index, o.regularizationMethods)}
                  />
                </TableCell>

                {multipleRows && (
                  <TableCell style={{ width: 20 }}>
                    <IconButton
                      size="small"
                      aria-label="remove"
                      disabled={rows.length <= 1}
                      onClick={handleDelete(index)}
                    >
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      {multipleRows && (
        <div className={classes.actions}>
          <IconButton aria-label="add" onClick={handleClick}>
            <AddIcon />
          </IconButton>
        </div>
      )}
    </div>
  );
}
HPTableRegularization.propTypes = HPTableRegularizationPropTypes;

HPTableRegularization.defaultProps = {};

export default HPTableRegularization;
