const prefix = '@qes/resources';

export const UPLOAD_RESOURCE_REQUEST = `${prefix}/UPLOAD_RESOURCE_REQUEST`;
export const UPLOAD_RESOURCE_SUCCESS = `${prefix}/UPLOAD_RESOURCE_SUCCESS`;
export const UPLOAD_RESOURCE_FAILURE = `${prefix}/UPLOAD_RESOURCE_FAILURE`;

export const UPDATE_UPLOAD_STATUS = `${prefix}/UPDATE_UPLOAD_STATUS`;

export const GET_RESOURCES_REQUEST = `${prefix}/GET_RESOURCES_REQUEST`;
export const GET_RESOURCES_SUCCESS = `${prefix}/GET_RESOURCES_SUCCESS`;
export const GET_RESOURCES_FAILURE = `${prefix}/GET_RESOURCES_FAILURE`;

export const GET_METADATA_REQUEST = `${prefix}/GET_METADATA_REQUEST`;
export const GET_METADATA_SUCCESS = `${prefix}/GET_METADATA_SUCCESS`;
export const GET_METADATA_FAILURE = `${prefix}/GET_METADATA_FAILURE`;

export const CREATE_FOLDER_REQUEST = `${prefix}/CREATE_FOLDER_REQUEST`;
export const CREATE_FOLDER_SUCCESS = `${prefix}/CREATE_FOLDER_SUCCESS`;
export const CREATE_FOLDER_FAILURE = `${prefix}/CREATE_FOLDER_FAILURE`;

export const DOWNLOAD_RESOURCE_REQUEST = `${prefix}/DOWNLOAD_RESOURCE_REQUEST`;
export const DOWNLOAD_RESOURCE_SUCCESS = `${prefix}/DOWNLOAD_RESOURCE_SUCCESS`;
export const DOWNLOAD_RESOURCE_FAILURE = `${prefix}/DOWNLOAD_RESOURCE_FAILURE`;

export const DELETE_RESOURCE_REQUEST = `${prefix}/DELETE_RESOURCE_REQUEST`;
export const DELETE_RESOURCE_SUCCESS = `${prefix}/DELETE_RESOURCE_SUCCESS`;
export const DELETE_RESOURCE_FAILURE = `${prefix}/DELETE_RESOURCE_FAILURE`;

export const UPDATE_RESOURCE_REQUEST = `${prefix}/UPDATE_RESOURCE_REQUEST`;
export const UPDATE_RESOURCE_SUCCESS = `${prefix}/UPDATE_RESOURCE_SUCCESS`;
export const UPDATE_RESOURCE_FAILURE = `${prefix}/UPDATE_RESOURCE_FAILURE`;

export const ADD_SINGLE_RESOURCE_ITEM = `${prefix}/ADD_SINGLE_RESOURCE_ITEM`;
