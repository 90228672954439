import * as types from '../workflows.types';
import client from 'utils/workflowClient';

export const getEdges = ({ workflowId, runId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.GET_EDGES_REQUEST
    });

    try {
      const { data = {} } = await client.get(`/workflows/${workflowId}/edges`);

      const { edges = [] } = data;

      return dispatch({
        type: types.GET_EDGES_SUCCESS,
        payload: {
          edges: edges
        }
      });
    } catch (err) {
      const errorMessage =
        err.response.status === 403
          ? 'You are not allowed to do this action. If you think you should have access, please contact support@quanticoenergy.com'
          : err.message;
      return dispatch({
        type: types.GET_EDGES_FAILURE,
        payload: {
          error: errorMessage
        }
      });
    }
  };
};

export const clearEdges = () => {
  return async (dispatch) => {
    try {
      return dispatch({
        type: types.CLEAR_EDGES_SUCCESS,
        payload: {
          edges: []
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CLEAR_EDGES_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const createEdge = ({ edge: newEdge, workflowId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.CREATE_EDGE_REQUEST
    });

    try {
      const { data = {} } = await client.post(`/workflows/${workflowId}/edges`, {
        sourceId: newEdge.source,
        targetId: newEdge.target
      });
      const { edge = {} } = data;
      return dispatch({
        type: types.CREATE_EDGE_SUCCESS,
        payload: {
          edge
        }
      });
    } catch (err) {
      return dispatch({
        type: types.CREATE_EDGE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const deleteEdge = ({ edges: edgesToDelete, workflowId }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.DELETE_EDGE_REQUEST
    });
    try {
      const { edges } = getState().workflows;
      const idsToRemove = edgesToDelete.map((o) => o.id);
      const newEdges = edges.filter((o) => {
        return !idsToRemove.includes(o.id);
      });

      for (let id of idsToRemove) {
        await client.post(`/workflows/${workflowId}/edges/${id}`, { deleted: true });
      }

      return dispatch({
        type: types.DELETE_EDGE_SUCCESS,
        payload: {
          edges: newEdges
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DELETE_EDGE_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
