export function testRequiredIntInput(inputValue) {
  const exp = new RegExp('^[0-9]+$');
  return exp.test(inputValue);
}

export function testOptionalIntegerInput(inputValue) {
  if (inputValue === '') return true;
  return testRequiredIntInput(inputValue);
}

export function testRequiredFloatInput(inputValue) {
  const exp = new RegExp('^[+-]?([0-9]*[.])?[0-9]+$');
  return exp.test(inputValue);
}
export function testOptionalFloatInput(inputValue) {
  if (inputValue === '') return true;
  return testRequiredFloatInput(inputValue);
}
