import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  }
}));

export function addToCSV(source, index, value) {
  const list = (source || '').split(',');
  list[index] = value;
  return list.join(',');
}

export function getCSV(source, index) {
  const list = (source || '').split(',');
  return list[index];
}
