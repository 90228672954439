import { NODE_TYPES } from 'utils/constants';
import CompileWellBase from './QEarth/CompileWellBase';
import CreateDataModel from './QEarth/CreateDataModel';
import CreateHorizon from './QEarth/CreateHorizon';
import CreateSeismic from './QEarth/CreateSeismic';
import HyperparameterExplorer from './QEarth/HyperparameterExplorer';

import EnsembleSimulations from './QEarth/EnsembleSimulations';
import EnsembleWellOverlay from './QEarth/EnsembleWellOverlay';
import ExportSimulations from './QEarth/ExportSimulations';
import SimulateWells from './QEarth/SimulateWells';
import SensitivityAnalysis from './QEarth/SensitivityAnalysis';
import MultiSimulateSeismic from './QEarth/MultiSimulateSeismic';
import WellIngestion from './QEarth/WellIngestion';
import ModelTraining from './QEarth/ModelTraining';
import QEarthFeatureEngineering from './QEarth/QEarthFeatureEngineering';
import BulkWellIngestion from './QEarth/BulkWellIngestion';
import BulkCreateSeismic from './QEarth/BulkCreateSeismic';
import QEarthFeatureEngineeringVisualizer from './QEarth/QEarthFeatureEngineeringVisualizer';
import OutputVisualizer from './QEarth/OutputVisualizer';

export const names = {
  [NODE_TYPES.COMPILE_WELL_BASE]: 'Compile Well Base',
  [NODE_TYPES.CREATE_DATA_MODEL]: 'Create Data Model',
  [NODE_TYPES.CREATE_HORIZON]: 'Import Horizon',
  [NODE_TYPES.CREATE_SEISMIC]: 'Import Seismic',
  [NODE_TYPES.ENSEMBLE_SIMULATIONS]: 'Ensemble Simulations',
  [NODE_TYPES.ENSEMBLE_WELL_OVERLAY]: 'Ensemble Well Overlay',
  [NODE_TYPES.EXPORT_SIMULATIONS]: 'Export Simulations',
  [NODE_TYPES.SIMULATE_WELLS]: 'Simulate Wells',
  [NODE_TYPES.HYPER_PARAMETER_EXPLORER]: 'Hyperparameter Explorer',
  [NODE_TYPES.SENSITIVITY_ANALYSIS]: 'Sensitivity Analysis',
  [NODE_TYPES.WELL_INGESTION]: 'Well Ingestion',
  [NODE_TYPES.MODEL_TRAINING]: 'Model Training',
  [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: 'Multi Simulate Seismic',
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: 'QEarth Feature Engineering',
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING_VISUALIZER]: 'QEarth Feature Engineering Visualizer',
  [NODE_TYPES.BULK_WELL_INGESTION]: 'Bulk Well Ingestion'
};

export default {
  [NODE_TYPES.COMPILE_WELL_BASE]: CompileWellBase,
  [NODE_TYPES.CREATE_DATA_MODEL]: CreateDataModel,
  [NODE_TYPES.CREATE_HORIZON]: CreateHorizon,
  [NODE_TYPES.CREATE_SEISMIC]: CreateSeismic,
  [NODE_TYPES.BUlK_CREATE_SEISMIC]: BulkCreateSeismic,
  [NODE_TYPES.HYPER_PARAMETER_EXPLORER]: HyperparameterExplorer,
  [NODE_TYPES.ENSEMBLE_SIMULATIONS]: EnsembleSimulations,
  [NODE_TYPES.ENSEMBLE_WELL_OVERLAY]: EnsembleWellOverlay,
  [NODE_TYPES.EXPORT_SIMULATIONS]: ExportSimulations,
  [NODE_TYPES.SIMULATE_WELLS]: SimulateWells,
  [NODE_TYPES.SENSITIVITY_ANALYSIS]: SensitivityAnalysis,
  [NODE_TYPES.WELL_INGESTION]: WellIngestion,
  [NODE_TYPES.MODEL_TRAINING]: ModelTraining,
  [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: MultiSimulateSeismic,
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: QEarthFeatureEngineering,
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING_VISUALIZER]: QEarthFeatureEngineeringVisualizer,
  [NODE_TYPES.BULK_WELL_INGESTION]: BulkWellIngestion,
  [NODE_TYPES.OUTPUT_VISUALIZER]: OutputVisualizer
};
