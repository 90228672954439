import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  name: {
    'display': 'flex',
    'alignItems': 'center',
    'cursor': 'pointer',
    '& svg': {
      marginRight: theme.spacing(1)
    }
  },
  loading: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 200
  },
  header: {
    '&.MuiTableCell-head': {
      fontSize: 12
    }
  },
  bodyText: {
    '&.MuiTableCell-body': {
      fontSize: 11
    }
  }
}));

export { useStyles };
