import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

import IconButton from '@material-ui/core/IconButton';
import Drawer from '@material-ui/core/Drawer';
import ArrowBackIosOutlinedIcon from '@material-ui/icons/ArrowBackIosOutlined';
import ArrowForwardIosOutlinedIcon from '@material-ui/icons/ArrowForwardIosOutlined';
import { useTheme } from '@material-ui/core';
import { useStyles } from './SectionDrawer.styles';

export const SectionDrawerPropTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.element.isRequired,
  fullScreen: PropTypes.bool,
  setFullScreen: PropTypes.func,
  showExpandButton: PropTypes.bool
};

function SectionDrawer({ children, open, onClose, fullScreen, setFullScreen, showExpandButton }) {
  const classes = useStyles({ open, fullScreen });
  const ref = useRef();
  const theme = useTheme();
  useEffect(() => {
    // this is a forced way to enable workflow actions buttons to be visible
    // using classes didn't work, possibly because the zindex belongs to the modal
    //that does not get rerendered on fullscreen changes
    if (ref.current) {
      ref.current.style.zIndex = fullScreen ? theme.zIndex.modal + 2 : theme.zIndex.modal;
    }
  }, [fullScreen]);

  return (
    <>
      <Drawer
        data-testid="SectionDrawer"
        open={!!open}
        anchor="right"
        classes={{ paper: classes.paper }}
        onClose={onClose}
        ref={ref}
      >
        {children}
        {!!showExpandButton && (
          <IconButton classes={{ root: classes.button }} onClick={(e) => setFullScreen(!fullScreen)} id="expand-button">
            {fullScreen ? (
              <ArrowForwardIosOutlinedIcon fontSize="large" color="primary" />
            ) : (
              <ArrowBackIosOutlinedIcon fontSize="large" color="primary" />
            )}
          </IconButton>
        )}
      </Drawer>
    </>
  );
}
SectionDrawer.propTypes = SectionDrawerPropTypes;

SectionDrawer.defaultProps = {
  fullScreen: false,
  setFullScreen: () => {},
  showExpandButton: true
};

export default SectionDrawer;
