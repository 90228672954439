import { makeStyles, darken } from '@material-ui/core/styles';

const wellTable = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  },
  uploadFile: {
    minWidth: '25px'
  },
  wellName: {
    minWidth: 45
  },
  remove: {
    width: 5
  },
  cellCollapse: {
    padding: 0,
    width: '20px'
  },
  devFileCellInput: (props) => ({
    flex: 1,
    display: 'flex',
    pointerEvents: props.dev_disabled ? 'none' : 'auto',
    backgroundColor: props.dev_disabled
      ? darken(theme.palette.background.default, 0.2)
      : theme.palette.background.default
  }),
  cellInput: {
    flex: 1,
    display: 'flex'
  },
  tdFileCellInput: (props) => ({
    flex: 1,
    display: 'flex',
    pointerEvents: props.td_disabled ? 'none' : 'auto',
    backgroundColor: props.td_disabled
      ? darken(theme.palette.background.default, 0.2)
      : theme.palette.background.default
  }),
  collapsedTable: {
    paddingBottom: 0,
    paddingTop: 0,
    backgroundColor: theme.palette.background.default,
    borderBottomRightRadius: theme.spacing(2),
    borderBottomLeftRadius: theme.spacing(2)
  }
}));

export { wellTable };
