import { NODE_TYPES } from './constants';

export const rules = {
  [NODE_TYPES.COMPILE_WELL_BASE]: {
    [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: true
  },
  [NODE_TYPES.CREATE_DATA_MODEL]: {
    [NODE_TYPES.HYPER_PARAMETER_EXPLORER]: true,
    [NODE_TYPES.MODEL_TRAINING]: true
  },
  [NODE_TYPES.HYPER_PARAMETER_EXPLORER]: {
    // This node has no output file.
    // To have a valid workflow is to have all nodes connected, so
    // this node has to connect to something or be final node.
    any: true
  },
  [NODE_TYPES.ENSEMBLE_WELL_OVERLAY]: {
    // This node has no output file.
    // To have a valid workflow is to have all nodes connected, so
    // this node has to connect to something or be final node.
    any: true
  },
  [NODE_TYPES.CREATE_HORIZON]: {
    [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: true
  },
  [NODE_TYPES.BUlK_CREATE_SEISMIC]: {
    [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: true,
    [NODE_TYPES.SENSITIVITY_ANALYSIS]: true,
    [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: true
  },
  [NODE_TYPES.CREATE_SEISMIC]: {
    [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: true,
    [NODE_TYPES.SENSITIVITY_ANALYSIS]: true,
    [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: true
  },
  [NODE_TYPES.ENSEMBLE_SIMULATIONS]: {
    [NODE_TYPES.EXPORT_SIMULATIONS]: true
  },
  [NODE_TYPES.EXPORT_SIMULATIONS]: {
    [NODE_TYPES.OUTPUT_VISUALIZER]: true
  },
  [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: {
    [NODE_TYPES.ENSEMBLE_SIMULATIONS]: true
  },
  [NODE_TYPES.SIMULATE_WELLS]: {
    [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: true,
    [NODE_TYPES.ENSEMBLE_WELL_OVERLAY]: true
  },
  [NODE_TYPES.SENSITIVITY_ANALYSIS]: {
    any: true
  },
  [NODE_TYPES.WELL_INGESTION]: {
    [NODE_TYPES.COMPILE_WELL_BASE]: true
  },
  [NODE_TYPES.BULK_WELL_INGESTION]: {
    [NODE_TYPES.COMPILE_WELL_BASE]: true
  },
  [NODE_TYPES.MODEL_TRAINING]: {
    [NODE_TYPES.MULTI_SIMULATE_SEISMIC]: true,
    [NODE_TYPES.SIMULATE_WELLS]: true,
    [NODE_TYPES.SENSITIVITY_ANALYSIS]: true
  },
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING]: {
    [NODE_TYPES.CREATE_DATA_MODEL]: true,
    [NODE_TYPES.QEARTH_FEATURE_ENGINEERING_VISUALIZER]: true
  },
  [NODE_TYPES.QEARTH_FEATURE_ENGINEERING_VISUALIZER]: {
    any: true
  },
  [NODE_TYPES.OUTPUT_VISUALIZER]: {
    any: true
  }
};

export const getConnectableNodes = (allNodes, node) => {
  if (!node) {
    return [];
  }

  const { nodeType } = node.data;
  const availableNodes = rules[nodeType];

  const connectable = allNodes.filter((e) => {
    return availableNodes[e.data.nodeType];
  });

  return connectable;
};

const getInputRules = () => {
  const result = {};
  Object.keys(NODE_TYPES).forEach((type) => {
    result[type] = [];
  });

  for (const [nodeType, outputs] of Object.entries(rules)) {
    Object.keys(outputs).forEach((type) => {
      const inputs = result[type] || [];

      if (!inputs.includes(nodeType)) {
        inputs.push(nodeType);
      }

      result[type] = inputs;
    });
  }
  return result;
};

export const inputRules = getInputRules();

export default { rules, inputRules, getConnectableNodes };
