import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import { testOptionalIntegerInput } from './utils';

export const IntegerInputPropTypes = {
  children: PropTypes.element,
  value: PropTypes.string.isRequired,
  check: PropTypes.bool
};

const IntegerInput = function ({ children, value, check = true, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...rest} value={value} error={check && !testOptionalIntegerInput(value)}>
      {children}
    </TextField>
  );
};

IntegerInput.propTypes = IntegerInputPropTypes;

IntegerInput.defaultProps = {
  children: <></>,
  check: true
};

export default IntegerInput;
