import { makeStyles, withStyles } from '@material-ui/core/styles';
import MuiPaper from '@material-ui/core/Paper';

export const nodeTypeScroll = makeStyles((theme) => ({
  root: {
    height: '100%',
    flex: 1,
    overflowY: 'auto',
    padding: theme.spacing(1),
    paddingBottom: theme.spacing(5)
  },
  inputWrapper: {
    overflow: 'hidden',
    height: 200
  },
  inputs: {
    'overflowY': 'auto',
    'height': '100%',
    'padding': theme.spacing(2),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

export const nodeTypeDefault = makeStyles((theme) => ({
  root: {
    'height': `calc(100% - ${theme.spacing(20)}px)`,
    'overflowY': 'auto',
    'padding': theme.spacing(0),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  inputWrapper: {
    overflow: 'hidden',
    height: 200
  },
  inputs: {
    'overflowY': 'auto',
    'height': '100%',
    'padding': theme.spacing(2),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  }
}));

export const nodeTypeActions = makeStyles((theme) => ({
  root: {
    height: `calc(100% - ${theme.spacing(22)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1)
  },
  inputWrapper: {
    overflow: 'hidden',
    height: 200
  },
  inputs: {
    'overflowY': 'auto',
    'height': '100%',
    'padding': theme.spacing(2),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export const nodeTypeHPE = makeStyles((theme) => ({
  root: {
    height: `calc(100% - ${theme.spacing(22)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1)
  },
  inputWrapper: {
    overflow: 'hidden',
    height: 200
  },
  inputs: {
    'overflowY': 'auto',
    'height': '100%',
    'padding': theme.spacing(2),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  horizontal: {
    'display': 'flex',
    'flexWrap': 'wrap',
    '& > *': {
      'margin-right': theme.spacing(1)
    }
  }
}));

export const nodeTypeScrollActions = makeStyles((theme) => ({
  root: {
    height: `calc(100% - ${theme.spacing(22)}px)`,
    overflowY: 'auto',
    padding: theme.spacing(1)
  },
  inputWrapper: {
    overflow: 'hidden',
    height: 200
  },
  inputs: {
    'overflowY': 'auto',
    'height': '100%',
    'padding': theme.spacing(2),
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end'
  }
}));

export const Paper = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    marginBottom: theme.spacing(2)
  }
}))(MuiPaper);

export default { nodeTypeScroll, nodeTypeDefault, nodeTypeActions, nodeTypeHPE, nodeTypeScrollActions, Paper };
