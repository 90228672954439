import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: ({ open, fullScreen }) => ({
    display: open ? 'flex' : 'none',
    borderRadius: 0,
    position: 'relative',
    padding: theme.spacing(2),
    backgroundColor: theme.palette.background.default,
    overflow: 'auto',
    height: '100vh',
    width: open ? (fullScreen ? '100%' : '30%') : '0px',

    zIndex: 2
  }),
  paper: ({ fullScreen }) => ({
    transition: 'width 0.3s ease-in-out !important',
    width: fullScreen ? '100%' : '30%',
    minWidth: '480px',
    display: 'flex',
    overflow: 'auto'
  }),
  dense: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.default
  },
  button: {
    position: 'fixed',
    top: '50%',
    left: 'inherit',
    transition: 'right 0.3s ease-in-out !important',
    zIndex: 2,
    padding: 0
  },
  backDrop: {
    backgroundColor: 'rgba(0,0,0,0)'
  }
}));

export { useStyles };
