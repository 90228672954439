import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NODE_TYPES } from 'utils/constants';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import TooltipTitle from 'components/Common/TooltipTitle';
import { testRequiredIntInput } from 'components/Nodes/Common/Components/utils';
import IntegerInput from 'components/Nodes/Common/Components/IntegerInput';
import Actions from '../../Common/Actions';
import { nodeTypeDefault, Paper } from '../../Styles';
import CommaSeparatedInput, {
  testOptionalCommaSeparatedInput,
  testRequiredCommaSeparatedInput
} from '../../Common/Components/CommaSeparatedInput';

export const MultiSimulateSeismicPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  simulate: 'planes',
  inlines: '',
  xlines: '',
  inline_start: null,
  inline_end: null,
  xline_start: null,
  xline_end: null,
  view_inline: '',
  view_xline: '',
  output_sample_rate: 2,
  top_band: 0,
  bottom_band: 0,
  advanced_parameters: false,
  workers: 5,
  nodeType: NODE_TYPES.MULTI_SIMULATE_SEISMIC
};

function MultiSimulateSeismic({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeDefault();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const shouldShowCrossInlines = values.simulate !== 'volume';
  const isSmallVolume = values.simulate === 'small_volume';
  const isPlanes = values.simulate === 'planes';
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const validateValues = () => {
    let isValid = !!values.name;
    if (isSmallVolume) {
      //needs start and end valids for both inline and xline
      const areValidSmallVolumeIntInputs =
        testRequiredIntInput(values.inline_start) &&
        testRequiredIntInput(values.inline_end) &&
        testRequiredIntInput(values.xline_start) &&
        testRequiredIntInput(values.xline_end) &&
        Number(values.inline_start) < Number(values.inline_end) &&
        Number(values.xline_start) < Number(values.xline_end);

      isValid = isValid && areValidSmallVolumeIntInputs;
    }
    if (isPlanes) {
      //needs at least one inline or one crossline
      const isValidPlanes =
        testRequiredCommaSeparatedInput(values.inlines) || testRequiredCommaSeparatedInput(values.xlines);
      isValid = isValid && isValidPlanes;
    }
    const validViews =
      testOptionalCommaSeparatedInput(values.view_inline) && testOptionalCommaSeparatedInput(values.view_xline);
    isValid = isValid && validViews;
    return isValid;
  };

  const handleChangeSimulateType = (event) => {
    const type = event.target.value;
    if (type === 'volume') {
      return setValues({
        ...values,
        inlines: null,
        xlines: null,
        inline_end: null,
        inline_start: null,
        xline_end: null,
        xline_start: null,
        simulate: type
      });
    }
    if (type === 'small_volume') {
      return setValues({
        ...values,
        inlines: null,
        xlines: null,
        simulate: type,
        inline_start: '',
        inline_end: '',
        xline_start: '',
        xline_end: ''
      });
    }
    // planes
    return setValues({
      ...values,
      inline_end: null,
      inline_start: null,
      xline_end: null,
      xline_start: null,
      inlines: '',
      xlines: '',
      simulate: type
    });
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleCheckboxChange = (prop) => (event) => {
    const value = event.target.checked;
    setValues({ ...values, [prop]: value });
  };

  const handleSave = () => {
    setSaveNode(true);
    if (validateValues(values)) {
      onSave(values);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues({ ...initialValues, ...data });
    }
  }, []);

  return (
    <div data-testid="MultiSimulateSeismic" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Simulation Type</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="simulate-select-label"
            id="simulate-select"
            value={values.simulate}
            onChange={handleChangeSimulateType}
          >
            <MenuItem value="planes">Sections</MenuItem>
            <MenuItem value="volume">Volume</MenuItem>
            <MenuItem value="small_volume">Small Cube</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      {shouldShowCrossInlines && (
        <>
          <Paper>
            <TooltipTitle>Inlines</TooltipTitle>
            {isPlanes ? (
              <CommaSeparatedInput
                id="inlines"
                margin="dense"
                fullWidth
                onChange={handleChange('inlines')}
                value={values.inlines}
                type="text"
              />
            ) : (
              <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
                <IntegerInput
                  margin="dense"
                  id="inline_start"
                  type="text"
                  value={values.inline_start}
                  style={{ width: '50%' }}
                  onChange={handleChange('inline_start')}
                  helperText="Start Inline"
                />
                <IntegerInput
                  margin="dense"
                  id="inline_end"
                  type="text"
                  value={values.inline_end}
                  style={{ width: '50%' }}
                  helperText="End Inline"
                  onChange={handleChange('inline_end')}
                />
              </div>
            )}
          </Paper>
          <Paper>
            <TooltipTitle>Crosslines</TooltipTitle>
            {isPlanes ? (
              <CommaSeparatedInput
                margin="dense"
                id="xlines"
                type="text"
                value={values.xlines}
                fullWidth
                onChange={handleChange('xlines')}
              />
            ) : (
              <div style={{ width: '100%', display: 'flex', gap: '16px' }}>
                <IntegerInput
                  margin="dense"
                  id="xline_start"
                  type="text"
                  value={values.xline_start}
                  style={{ width: '50%' }}
                  onChange={handleChange('xline_start')}
                  helperText="Start Crossline"
                />
                <IntegerInput
                  margin="dense"
                  id="xline_end"
                  type="text"
                  value={values.xline_end}
                  style={{ width: '50%' }}
                  helperText="End Crossline"
                  onChange={handleChange('xline_end')}
                />
              </div>
            )}
          </Paper>
        </>
      )}
      <Paper>
        <TooltipTitle>Plot Inlines</TooltipTitle>
        <CommaSeparatedInput
          id="view_inline"
          margin="dense"
          fullWidth
          onChange={handleChange('view_inline')}
          value={values.view_inline}
          type="text"
        />
      </Paper>
      <Paper>
        <TooltipTitle>Plot Crosslines</TooltipTitle>
        <CommaSeparatedInput
          id="view_xline"
          margin="dense"
          fullWidth
          onChange={handleChange('view_xline')}
          value={values.view_xline}
          type="text"
        />
      </Paper>
      <FormControlLabel
        control={(
          <Checkbox
            checked={values.advanced_parameters}
            value={values.advanced_parameters}
            onChange={handleCheckboxChange('advanced_parameters')}
            name="advanced_parameters"
          />
        )}
        label="Configure Advanced Parameters"
      />
      {values.advanced_parameters && advancedParametersSection({ values, handleChange })}
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

function advancedParametersSection({ values, handleChange }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Custom Sample Rate</TooltipTitle>
        <TextField
          margin="dense"
          id="output_sample_rate"
          type="number"
          InputProps={{ inputProps: { min: 0, max: 10 } }}
          value={values.output_sample_rate}
          fullWidth
          onChange={handleChange('output_sample_rate')}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Top Buffer</TooltipTitle>
        <TextField
          margin="dense"
          id="top_band"
          type="number"
          value={values.top_band}
          fullWidth
          onChange={handleChange('top_band')}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Bottom Buffer</TooltipTitle>
        <TextField
          margin="dense"
          id="bottom_band"
          type="number"
          value={values.bottom_band}
          fullWidth
          onChange={handleChange('bottom_band')}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Number of Parallel Jobs (Workers)</TooltipTitle>
        <TextField
          margin="dense"
          id="workers"
          type="number"
          InputProps={{ inputProps: { min: 1, max: 10 } }}
          value={values.workers}
          fullWidth
          onChange={handleChange('workers')}
        />
      </Paper>
    </div>
  );
}

MultiSimulateSeismic.propTypes = MultiSimulateSeismicPropTypes;

MultiSimulateSeismic.defaultProps = {};

export default MultiSimulateSeismic;
