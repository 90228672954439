import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TooltipTitle from 'components/Common/TooltipTitle';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { NODE_TYPES } from 'utils/constants';

import OutlinedInput from '@material-ui/core/OutlinedInput';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Actions from '../../Common/Actions';
import { nodeTypeActions, Paper } from '../../Styles';

export const CreateDataModelPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  non_seismic_features: '',
  targets: '',
  seismic_features: '',
  seismic_features_named: '',
  nodeType: NODE_TYPES.CREATE_DATA_MODEL
};

function CreateDataModel({ data, isCreated = false, onCancel, onSave, onShowLogs, configRetriever }) {
  const classes = nodeTypeActions();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const [availableSeismics, setAvailableSeismics] = useState([]);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleTOPSChange = (event) => {
    const selected = event.target.checked;
    const non_seismic_value = selected ? 'TOPS' : '';

    setValues({ ...values, non_seismic_features: non_seismic_value });
  };

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleSeismicFeaturesChange = (event) => {
    const {
      target: { value }
    } = event;
    const selectedElements = typeof value === 'string' ? value.split(',') : value;
    const valid = selectedElements.filter((e) => availableSeismics.includes(e)).sort((a, b) => a.localeCompare(b));
    setValues({ ...values, seismic_features_named: valid.join(',') });
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }

    async function retrieveSeismics() {
      const available = await configRetriever({ configName: 'reference_name', nodeType: NODE_TYPES.CREATE_SEISMIC });
      const bulkSeismicFilesAvailable = await configRetriever({
        configName: 'seismic_files',
        nodeType: NODE_TYPES.BUlK_CREATE_SEISMIC
      });
      const bulkAvailable = bulkSeismicFilesAvailable.filter((seismic) => !!seismic).map((s) => s.seismic_name);
      const readable = available.filter((e) => !!e);
      const merged = [...bulkAvailable, ...readable].sort((a, b) => a.localeCompare(b));
      setAvailableSeismics(merged);
    }

    if (configRetriever) {
      retrieveSeismics();
    }
  }, []);

  const currentNamedSeismic = (values.seismic_features_named && values.seismic_features_named.split(',')) || [];

  return (
    <div data-testid="CreateDataModel" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle>Input Seismic Feature Selection</TooltipTitle>
        <FormControl style={{ width: '100%' }}>
          <Select
            id="seismic-feature-named-group"
            multiple
            value={currentNamedSeismic}
            onChange={handleSeismicFeaturesChange}
            input={<OutlinedInput label="Tag" />}
            renderValue={(selected) => selected.join(', ')}
            MenuProps={{
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'left'
              },
              transformOrigin: {
                vertical: 'top',
                horizontal: 'left'
              },
              getContentAnchorEl: null
            }}
          >
            {availableSeismics.map((name) => (
              <MenuItem key={name} value={name}>
                <Checkbox checked={currentNamedSeismic.indexOf(name) > -1} />
                <ListItemText primary={name} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle>Targets</TooltipTitle>
        <TextField
          margin="dense"
          id="targets"
          label="Targets"
          fullWidth
          onChange={handleChange('targets')}
          value={values.targets}
        />
      </Paper>

      <FormControlLabel
        control={
          <Checkbox
            checked={values.non_seismic_features.includes('TOPS')}
            value={values.non_seismic_features.includes('TOPS')}
            onChange={handleTOPSChange}
            name="include_spatial_horizon"
          />
        }
        label="Include Spatial Horizon Feature"
      />
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

CreateDataModel.propTypes = CreateDataModelPropTypes;
CreateDataModel.defaultProps = {};

export default CreateDataModel;
