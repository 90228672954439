const prefix = '@qes/workflows';

export const GET_WORKFLOWS_REQUEST = `${prefix}/GET_WORKFLOWS_REQUEST`;
export const GET_WORKFLOWS_SUCCESS = `${prefix}/GET_WORKFLOWS_SUCCESS`;
export const GET_WORKFLOWS_FAILURE = `${prefix}/GET_WORKFLOWS_FAILURE`;

export const GET_WORKFLOW_REQUEST = `${prefix}/GET_WORKFLOW_REQUEST`;
export const GET_WORKFLOW_SUCCESS = `${prefix}/GET_WORKFLOW_SUCCESS`;
export const GET_WORKFLOW_FAILURE = `${prefix}/GET_WORKFLOW_FAILURE`;

export const CREATE_WORKFLOW_REQUEST = `${prefix}/CREATE_WORKFLOW_REQUEST`;
export const CREATE_WORKFLOW_SUCCESS = `${prefix}/CREATE_WORKFLOW_SUCCESS`;
export const CREATE_WORKFLOW_FAILURE = `${prefix}/CREATE_WORKFLOW_FAILURE`;

export const DELETE_WORKFLOW_REQUEST = `${prefix}/DELETE_WORKFLOW_REQUEST`;
export const DELETE_WORKFLOW_SUCCESS = `${prefix}/DELETE_WORKFLOW_SUCCESS`;
export const DELETE_WORKFLOW_FAILURE = `${prefix}/DELETE_WORKFLOW_FAILURE`;

export const UPDATE_WORKFLOW_REQUEST = `${prefix}/UPDATE_WORKFLOW_REQUEST`;
export const UPDATE_WORKFLOW_SUCCESS = `${prefix}/UPDATE_WORKFLOW_SUCCESS`;
export const UPDATE_WORKFLOW_FAILURE = `${prefix}/UPDATE_WORKFLOW_FAILURE`;

export const DUPLICATE_WORKFLOW_RESET = `${prefix}/DUPLICATE_WORKFLOW_RESET`;
export const DUPLICATE_WORKFLOW_REQUEST = `${prefix}/DUPLICATE_WORKFLOW_REQUEST`;
export const DUPLICATE_WORKFLOW_SUCCESS = `${prefix}/DUPLICATE_WORKFLOW_SUCCESS`;
export const DUPLICATE_WORKFLOW_FAILURE = `${prefix}/DUPLICATE_WORKFLOW_FAILURE`;

export const GET_NODES_REQUEST = `${prefix}/GET_NODES_REQUEST`;
export const GET_NODES_SUCCESS = `${prefix}/GET_NODES_SUCCESS`;
export const GET_NODES_FAILURE = `${prefix}/GET_NODES_FAILURE`;

export const CLEAR_NODES_SUCCESS = `${prefix}/CLEAR_NODES_SUCCESS`;
export const CLEAR_NODES_FAILURE = `${prefix}/CLEAR_NODES_FAILURE`;

export const UPDATE_NODE_REQUEST = `${prefix}/UPDATE_NODE_REQUEST`;
export const UPDATE_NODE_SUCCESS = `${prefix}/UPDATE_NODE_SUCCESS`;
export const UPDATE_NODE_FAILURE = `${prefix}/UPDATE_NODE_FAILURE`;

export const GET_EDGES_REQUEST = `${prefix}/GET_EDGES_REQUEST`;
export const GET_EDGES_SUCCESS = `${prefix}/GET_EDGES_SUCCESS`;
export const GET_EDGES_FAILURE = `${prefix}/GET_EDGES_FAILURE`;

export const CLEAR_EDGES_SUCCESS = `${prefix}/CLEAR_EDGES_SUCCESS`;
export const CLEAR_EDGES_FAILURE = `${prefix}/CLEAR_EDGES_FAILURE`;

export const CREATE_EDGE_REQUEST = `${prefix}/CREATE_EDGE_REQUEST`;
export const CREATE_EDGE_SUCCESS = `${prefix}/CREATE_EDGE_SUCCESS`;
export const CREATE_EDGE_FAILURE = `${prefix}/CREATE_EDGE_FAILURE`;

export const DELETE_EDGE_REQUEST = `${prefix}/DELETE_EDGE_REQUEST`;
export const DELETE_EDGE_SUCCESS = `${prefix}/DELETE_EDGE_SUCCESS`;
export const DELETE_EDGE_FAILURE = `${prefix}/DELETE_EDGE_FAILURE`;

export const CREATE_NODE_REQUEST = `${prefix}/CREATE_NODE_REQUEST`;
export const CREATE_NODE_SUCCESS = `${prefix}/CREATE_NODE_SUCCESS`;
export const CREATE_NODE_FAILURE = `${prefix}/CREATE_NODE_FAILURE`;

export const START_WORKFLOW_REQUEST = `${prefix}/START_WORKFLOW_REQUEST`;
export const START_WORKFLOW_SUCCESS = `${prefix}/START_WORKFLOW_SUCCESS`;
export const START_WORKFLOW_FAILURE = `${prefix}/START_WORKFLOW_FAILURE`;

export const WORKFLOW_VALIDATION_REQUEST = `${prefix}/WORKFLOW_VALIDATION_REQUEST`;
export const WORKFLOW_VALIDATION_SUCCESS = `${prefix}/WORKFLOW_VALIDATION_SUCCESS`;
export const WORKFLOW_VALIDATION_FAILURE = `${prefix}/WORKFLOW_VALIDATION_FAILURE`;
export const WORKFLOW_VALIDATION_PENDING = `${prefix}/WORKFLOW_VALIDATION_PENDING`;

export const STOP_WORKFLOW_REQUEST = `${prefix}/STOP_WORKFLOW_REQUEST`;
export const STOP_WORKFLOW_SUCCESS = `${prefix}/STOP_WORKFLOW_SUCCESS`;
export const STOP_WORKFLOW_FAILURE = `${prefix}/STOP_WORKFLOW_FAILURE`;

export const PAUSE_WORKFLOW_REQUEST = `${prefix}/PAUSE_WORKFLOW_REQUEST`;
export const PAUSE_WORKFLOW_SUCCESS = `${prefix}/PAUSE_WORKFLOW_SUCCESS`;
export const PAUSE_WORKFLOW_FAILURE = `${prefix}/PAUSE_WORKFLOW_FAILURE`;

export const GET_NODE_VISUALIZATIONS_REQUEST = `${prefix}/GET_NODE_VISUALIZATIONS_REQUEST`;
export const GET_NODE_VISUALIZATIONS_SUCCESS = `${prefix}/GET_NODE_VISUALIZATIONS_SUCCESS`;
export const GET_NODE_VISUALIZATIONS_FAILURE = `${prefix}/GET_NODE_VISUALIZATIONS_FAILURE`;

export const UPDATE_WORKFLOW_STATUS_REQUEST = `${prefix}/UPDATE_WORKFLOW_STATUS_REQUEST`;
export const UPDATE_WORKFLOW_STATUS_SUCCESS = `${prefix}/UPDATE_WORKFLOW_STATUS_SUCCESS`;
export const UPDATE_WORKFLOW_STATUS_FAILURE = `${prefix}/UPDATE_WORKFLOW_STATUS_FAILURE`;

export const UPDATE_NODE_STATUS_REQUEST = `${prefix}/UPDATE_NODE_STATUS_REQUEST`;
export const UPDATE_NODE_STATUS_SUCCESS = `${prefix}/UPDATE_NODE_STATUS_SUCCESS`;
export const UPDATE_NODE_STATUS_FAILURE = `${prefix}/UPDATE_NODE_STATUS_FAILURE`;

export const DELETE_NODE_REQUEST = `${prefix}/DELETE_NODE_REQUEST`;
export const DELETE_NODE_SUCCESS = `${prefix}/DELETE_NODE_SUCCESS`;
export const DELETE_NODE_FAILURE = `${prefix}/DELETE_NODE_FAILURE`;

export const GET_NODE_LOGS_REQUEST = `${prefix}/GET_NODE_LOGS_REQUEST`;
export const GET_NODE_LOGS_SUCCESS = `${prefix}/GET_NODE_LOGS_SUCCESS`;
export const GET_NODE_LOGS_FAILURE = `${prefix}/GET_NODE_LOGS_FAILURE`;

export const LIST_NODE_OUTPUT_REQUEST = `${prefix}/LIST_NODE_OUTPUT_REQUEST`;
export const LIST_NODE_OUTPUT_SUCCESS = `${prefix}/LIST_NODE_OUTPUT_SUCCESS`;
export const LIST_NODE_OUTPUT_FAILURE = `${prefix}/LIST_NODE_OUTPUT_FAILURE`;

export const DOWNLOAD_NODE_OUTPUT_REQUEST = `${prefix}/DOWNLOAD_NODE_OUTPUT_REQUEST`;
export const DOWNLOAD_NODE_OUTPUT_SUCCESS = `${prefix}/DOWNLOAD_NODE_OUTPUT_SUCCESS`;
export const DOWNLOAD_NODE_OUTPUT_FAILURE = `${prefix}/DOWNLOAD_NODE_OUTPUT_FAILURE`;

export const COPY_NODES_EDGES_REQUEST = `${prefix}/COPY_NODES_EDGES_REQUEST`;
export const COPY_NODES_EDGES_SUCCESS = `${prefix}/COPY_NODES_EDGES_SUCCESS`;
export const COPY_NODES_EDGES_FAILURE = `${prefix}/COPY_NODES_EDGES_FAILURE`;

export const UPDATE_SELECTION_POSITION_REQUEST = `${prefix}/UPDATE_SELECTION_POSITION_REQUEST`;
export const UPDATE_SELECTION_POSITION_SUCCESS = `${prefix}/UPDATE_SELECTION_POSITION_SUCCESS`;
export const UPDATE_SELECTION_POSITION_FAILURE = `${prefix}/UPDATE_SELECTION_POSITION_FAILURE`;

export const GET_DASHBOARD_WORKFLOW_REQUEST = `${prefix}/GET_DASHBOARD_WORKFLOW_REQUEST`;
export const GET_DASHBOARD_WORKFLOW_SUCCESS = `${prefix}/GET_DASHBOARD_WORKFLOW_SUCCESS`;
export const GET_DASHBOARD_WORKFLOW_FAILURE = `${prefix}/GET_DASHBOARD_WORKFLOW_FAILURE`;

export const CLEAR_CURRENT_WORKFLOW = `${prefix}/CLEAR_CURRENT_WORKFLOW`;
