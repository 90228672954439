import { createLayoutStyle } from 'components/PlotlyPlot/PlotlyPlot.styles';
import client from 'utils/workflowClient';
import PropTypes from 'prop-types';

const defaultLoader = (chartId, getChartDataFn) => {
  return async (params) => {
    return await getChartDataFn({ chartId: chartId, params: params });
  };
};

const NoDataChart = () => {
  return (
    <div>
      <h3>Not data available</h3>
    </div>
  );
};

const updatePlotStyle = ({ xCol, yCol, title, yAutorange, x2Col, y2Col }) => {
  return (style) => {
    if (title) {
      style.title = title;
    }
    if (xCol) {
      style.xaxis.title = xCol;
    }
    if (yCol) {
      style.yaxis.title = yCol;
    }
    if (yAutorange) {
      style.yaxis.autorange = yAutorange;
    }
    if (x2Col && style.xaxis2) {
      style.xaxis2.title = x2Col;
    }
    if (y2Col && style.yaxis2) {
      style.yaxis2.title = y2Col;
    }
    return style;
  };
};

const multipleXAxisFormatter = (series, userInput) => {
  const names = series.map((s) => s.name);
  const areAllEqual = names.every((value, index, array) => value === array[0]);
  let formatted = series;

  if (!areAllEqual) {
    formatted = series.map((serie, index) => {
      return { ...serie, xaxis: `x${index + 1}` };
    });
  }

  return formatted;
};

const defaultPlotStyle = (params, theme) => {
  const safeParams = params || {};

  const base = createLayoutStyle(safeParams.layoutOverrides, theme);
  const styling = updatePlotStyle(safeParams);
  return styling(base);
};

const ChartPropTypes = {
  chart: PropTypes.object.isRequired,
  layoutOverrides: PropTypes.object
};

const tryParseJsonWithNans = (data) => {
  try {
    //first we try with json
    return JSON.parse(data.replace(/\bNaN\b/g, '"***NaN***"'), function (key, value) {
      return value === '***NaN***' ? NaN : value;
    });
  } catch (excep) {
    //now we try with gunzip
    const result = zlib.gunzipSync(Buffer.from(data, 'base64'));
    const parsedData = JSON.parse(result.toString().replace(/\bNaN\b/g, '"***NaN***"'), function (key, value) {
      return value === '***NaN***' ? NaN : value;
    });
    return parsedData;
  }
};

export {
  defaultLoader,
  NoDataChart,
  updatePlotStyle,
  defaultPlotStyle,
  ChartPropTypes,
  multipleXAxisFormatter,
  tryParseJsonWithNans
};
