import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import CircularProgress from '@material-ui/core/CircularProgress';
import FolderIcon from '@material-ui/icons/Folder';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useStyles } from './ResourcesTable.styles';
import TableHeader from './TableHeader';

const ResourceItemPropTypes = {
  id: PropTypes.number.isRequired,
  folderPath: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
  resourceType: PropTypes.string.isRequired,
  createdAt: PropTypes.string.isRequired,
  contentLength: PropTypes.number
};

export const ResourcesTablePropTypes = {
  resources: PropTypes.arrayOf(PropTypes.shape(ResourceItemPropTypes)).isRequired,
  loading: PropTypes.bool,
  onClick: PropTypes.func.isRequired
};

const formatResourceDate = (date) => format(parseISO(`${date}Z`), 'PPPppp');

const sort_by = (field, reverse, primer) => {
  const key = primer
    ? function (x) {
        return primer(x[field]);
      }
    : function (x) {
        return x[field];
      };

  reverse = !reverse ? 1 : -1;

  return function (a, b) {
    return (a = key(a)), (b = key(b)), reverse * ((a > b) - (b > a));
  };
};

function ResourcesTable({ resources, selectedResources, loading, onClick, handleChecked, openEditModal }) {
  const classes = useStyles();
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('filename');

  const getName = (item) => {
    if (item.resourceType === 'Folder') {
      const names = item.filename.split('/');
      if (names[names.length - 1] === '') names.pop();
      return names[names.length - 1];
    }
    return (
      <span>
        <span>{item.filename}</span>
        {!item.isUploaded && <span style={{ color: 'red' }}>{` (Incomplete)`}</span>}
      </span>
    );
  };

  const handleRequestSort = (event, property) => {
    const isAscending = orderBy === property && order === 'asc';
    setOrder(isAscending ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const sortedInformation = resources.sort(sort_by(orderBy, order === 'asc', (a) => a.toUpperCase()));

  return (
    <div data-testid="ResourcesTable">
      <TableContainer>
        <Table aria-label="resources">
          <TableHeader
            order={order}
            orderBy={orderBy}
            handleRequestSort={handleRequestSort}
            showOptions={!!openEditModal}
          />
          <TableBody>
            {!loading &&
              sortedInformation.map((resource, index) => {
                const labelId = `table-checkbox-${index}`;
                return (
                  <TableRow
                    key={`${resource.id}-${resource.filename}`}
                    id={`${resource.id}-${resource.filename}`}
                    hover
                    role="checkbox"
                    tabIndex={-1}
                  >
                    <TableCell padding="checkbox">
                      <Checkbox
                        inputProps={{ 'aria-labelledby': labelId }}
                        checked={selectedResources.includes(resource.id)}
                        onChange={handleChecked(resource)}
                      />
                    </TableCell>
                    <TableCell
                      component="th"
                      scope="row"
                      id={labelId}
                      onClick={onClick(resource)}
                      className={classes.bodyText}
                    >
                      <div className={classes.name}>
                        {resource.resourceType === 'Folder' && <FolderIcon color="primary" />}
                        {resource.resourceType === 'File' && <InsertDriveFileIcon />}
                        {getName(resource)}
                      </div>
                    </TableCell>
                    <TableCell className={classes.bodyText}>{formatResourceDate(resource.createdAt)}</TableCell>
                    {openEditModal && (
                      <TableCell>
                        <IconButton onClick={openEditModal(resource.id)}>
                          <EditIcon color="primary" />
                        </IconButton>
                      </TableCell>
                    )}
                  </TableRow>
                );
              })}
          </TableBody>
        </Table>
      </TableContainer>
      {loading && (
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      )}
    </div>
  );
}
ResourcesTable.propTypes = ResourcesTablePropTypes;

ResourcesTable.defaultProps = {
  loading: false
};

export default ResourcesTable;
