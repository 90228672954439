import { makeStyles } from '@material-ui/core/styles';
import indigo from '@material-ui/core/colors/indigo';
import grey from '@material-ui/core/colors/grey';
import green from '@material-ui/core/colors/green';
import orange from '@material-ui/core/colors/orange';
import red from '@material-ui/core/colors/red';
import cyan from '@material-ui/core/colors/cyan';
import {
  NODE_RUNNING,
  NODE_COMPLETED,
  NODE_ERROR,
  NODE_STOPPED,
  NODE_PAUSED,
  NODE_CATEGORY_WELL_CONDITIONING,
  NODE_CATEGORY_DATA_VISUALIZATION,
  NODE_CATEGORY_FEATURE_ENGINEERING,
  NODE_CATEGORY_MODEL_DEVELOPMENT,
  NODE_CATEGORY_MODEL_UTILIZATION,
  NODE_CATEGORY_RESOURCES
} from 'utils/constants';

const width = 200;
const height = 60;

export const nodeStyles = makeStyles((theme) => ({
  root: {
    'padding': theme.spacing(2),
    width,
    height,
    'backgroundColor': indigo[500],
    'color': indigo[50],
    'transition': '0.3s',
    '&:hover': {
      backgroundColor: cyan[500]
    }
  },
  selected: {
    backgroundColor: cyan[600] + ' !important'
  },
  status: {
    position: 'absolute',
    width: 20,
    height: 20,
    borderRadius: 10,
    right: -5,
    top: -5,
    backgroundColor: grey[300],
    boxShadow: `0px 0px 4px 2px ${grey.A700}`
  },
  disabled: {
    opacity: 0.5
  },
  connectable: {
    boxShadow: `1px 1px 10px 3px ${theme.palette.primary.main}`
  },
  [NODE_COMPLETED]: {
    backgroundColor: green[300],
    boxShadow: `0px 0px 4px 2px ${green.A700}`
  },
  [NODE_RUNNING]: {
    backgroundColor: orange[500],
    boxShadow: `0px 0px 4px 2px ${orange.A700}`
  },
  [NODE_ERROR]: {
    backgroundColor: red[500],
    boxShadow: `0px 0px 4px 2px ${red.A700}`
  },
  [NODE_STOPPED]: {
    backgroundColor: red[500],
    boxShadow: `0px 0px 4px 2px ${red.A700}`
  },
  [NODE_PAUSED]: {
    backgroundColor: grey[500],
    boxShadow: `0px 0px 4px 2px ${grey.A700}`
  },
  [NODE_CATEGORY_WELL_CONDITIONING]: {
    'backgroundColor': '#673ab7',
    '&.react-flow__handle': {
      background: '#673ab7'
    }
  },
  [NODE_CATEGORY_DATA_VISUALIZATION]: {
    'backgroundColor': '#c62828',
    '&.react-flow__handle': {
      background: '#c62828'
    }
  },
  [NODE_CATEGORY_FEATURE_ENGINEERING]: {
    'backgroundColor': '#303f9f',
    '&.react-flow__handle': {
      background: '#303f9f'
    }
  },
  [NODE_CATEGORY_MODEL_DEVELOPMENT]: {
    'backgroundColor': '#ff3c7c',
    '&.react-flow__handle': {
      background: '#ff3c7c'
    }
  },
  [NODE_CATEGORY_MODEL_UTILIZATION]: {
    'backgroundColor': '#ff6f00',
    '&.react-flow__handle': {
      background: '#ff6f00'
    }
  },
  [NODE_CATEGORY_RESOURCES]: {
    'backgroundColor': '#00cceb',
    '&.react-flow__handle': {
      background: '#00cceb'
    }
  },
  dataIngestion: {
    'backgroundColor': '#00cceb',
    '&.react-flow__handle': {
      background: '#00cceb'
    }
  },
  features: {
    'backgroundColor': '#303f9f',
    '&.react-flow__handle': {
      background: '#303f9f'
    }
  },
  training: {
    'backgroundColor': '#ff3c7c',
    '&.react-flow__handle': {
      background: '#ff3c7c'
    }
  },
  logPrediction: {
    'backgroundColor': '#ff6f00',
    '&.react-flow__handle': {
      background: '#ff6f00'
    }
  },
  rootLegend: {
    padding: '0 20px',
    color: '#ffffff',
    textTransform: 'uppercase',
    borderRadius: '5px'
  },
  inputDiv: {
    display: 'flex',
    justifyContent: 'space-around'
  }
}));
