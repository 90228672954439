import React from 'react';
import { useStyles } from './ResourcesTable.styles';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';

export default function TableHeader({ orderBy, order, handleRequestSort, showOptions = true }) {
  const classes = useStyles();

  const handleSort = (property) => (event) => {
    handleRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox" />
        <TableCell key="filename" className={classes.header}>
          <TableSortLabel
            active={orderBy === 'filename'}
            direction={orderBy == 'filename' ? order : 'asc'}
            onClick={handleSort('filename')}
          >
            Name
          </TableSortLabel>
        </TableCell>

        <TableCell key="createdAt" className={classes.header}>
          <TableSortLabel
            active={orderBy === 'createdAt'}
            direction={orderBy == 'createdAt' ? order : 'asc'}
            onClick={handleSort('createdAt')}
          >
            Last modified
          </TableSortLabel>
        </TableCell>
        {showOptions && <TableCell className={classes.header}>Options</TableCell>}
      </TableRow>
    </TableHead>
  );
}
