export function attachExecution(workflows, workflowId, execution) {
  const updatedWorkflows = workflows.map((o) => {
    if (`${o.id}` !== `${workflowId}`) return o;
    return {
      ...o,
      executions: [...o.executions, execution]
    };
  });
  return updatedWorkflows;
}

export function extractStatus(workflows) {
  const nodeStatus = {};

  workflows.forEach((o) => {
    if (!o.executions) return;
    o.executions.forEach((execution) => {
      if (!execution.nodes) return;
      execution.nodes.forEach((node) => {
        nodeStatus[node.uuid] = node.status;
      });
    });
  });

  return nodeStatus;
}

export function extractExecutionStatus(executions) {
  const nodeStatus = {};
  executions.forEach((execution) => {
    execution.nodes.forEach((node) => {
      nodeStatus[node.uuid] = node;
    });
  });
  return nodeStatus;
}

export function extractNodeStatus(nodes) {
  const nodeStatus = {};

  nodes.forEach((node) => {
    nodeStatus[node.uuid] = node.status;
  });

  return nodeStatus;
}
