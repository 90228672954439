import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Tooltip from '@material-ui/core/Tooltip';
import CircularProgress from '@material-ui/core/CircularProgress';
import mappings, { names } from './mappings';
import { useStyles } from './Nodes.styles';
import configRetriever from './configRetriever';

export const NodesPropTypes = {
  element: PropTypes.object,
  node: PropTypes.object,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  onDownloadOutputFiles: PropTypes.func
};

function Nodes({
  workflow,
  node,
  element,
  inline = false,
  onCancel,
  onSave,
  onDelete,
  onShowLogs,
  onDownloadOutputFiles
}) {
  const classes = useStyles();
  const isUpdatingNode = useSelector((state) => state.workflows.isUpdatingNode);

  const handleDeleteConfirm = () => {
    if (element) onDelete(element.id);
    onCancel();
  };

  const createRetriever = (workflowId) => {
    return async ({ nodeType, configName }) => {
      const data = await configRetriever({ workflowId, nodeType, configName });
      return data;
    };
  };

  let Node;
  let title = '';
  if (node) {
    Node = mappings[node?.id];
    title = node?.title;
  } else if (element) {
    Node = mappings[element?.data?.nodeType];
    title = element?.data?.name;
  }
  if (isUpdatingNode) {
    return (
      <div data-testid="Nodes" className={cx(classes.root, { [classes.inline]: inline })}>
        <Typography variant="h5" className={classes.title}>
          Updating
        </Typography>
        <div className={classes.loading}>
          <CircularProgress />
        </div>
      </div>
    );
  }

  if (!Node) {
    return (
      <div data-testid="Nodes" className={cx(classes.root, { [classes.inline]: inline })}>
        <Typography variant="h5" className={classes.title}>
          Select a node
        </Typography>
        {!inline && (
          <Button onClick={onCancel} color="secondary" variant="outlined">
            Go Back
          </Button>
        )}
      </div>
    );
  }
  return (
    <div data-testid="Nodes" className={cx(classes.root, { [classes.inline]: inline })}>
      <div className={classes.header}>
        <div className={classes.nodeTitle}>
          <Tooltip
            placement="bottom-start"
            title={(element && element.data && names[element.data.nodeType]) || ''}
            className={classes.tooltip}
          >
            <Typography variant="h5" className={classes.title}>
              {title}
            </Typography>
          </Tooltip>
          {element && element.data && onDelete && (
            <IconButton aria-label="remove" onClick={handleDeleteConfirm}>
              <DeleteIcon color="secondary" />
            </IconButton>
          )}
        </div>
        {element && element.id && (
          <div>
            <Typography variant="caption" className={classes.nodeId}>{`Node ID: ${element.id}`}</Typography>
          </div>
        )}
      </div>
      <Node
        key={element && element.id}
        data={element && element.data}
        isCreated={Boolean(element && element.data)}
        onCancel={onCancel}
        onSave={onSave}
        onShowLogs={onShowLogs}
        onDownloadOutputFiles={onDownloadOutputFiles}
        configRetriever={createRetriever(workflow && workflow.id)}
      />
    </div>
  );
}
Nodes.propTypes = NodesPropTypes;

Nodes.defaultProps = {};

export default Nodes;
