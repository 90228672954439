const createQueryOptions = (chart, config) => {
  const options = {};
  const hidden = config.hiddenProperties || [];
  Object.entries(chart.summary).map(([filename, properties]) => {
    const columns = (properties.columns || properties.y_keys || []).filter((n) => !hidden.includes(n)).concat('-');
    const name = filename.replace('.csv', '');
    const wells = properties.wells || null;

    if (wells) {
      for (const wellname of wells) {
        options[`${name} - ${wellname}`] = {
          query: {
            file_id: properties.file_id,
            filter_value: wellname,
            filter_column: 'well'
          },
          columns
        };
      }
    } else {
      options[name] = {
        query: {
          file_id: properties.file_id
        },
        columns
      };
    }
  });
  return options;
};

const defaultQueryResolver = (inputs, config) => {
  let params = {
    series: []
  };

  inputs.forEach((input, index) => {
    const { file, x, y, filterValue, filterColumn } = input;
    const inputs = [file, x, y, filterValue, filterColumn];
    const allValid = inputs.every((i) => i !== '-');

    if (!allValid) {
      return;
    }

    const param = {
      x_col: x,
      y_col: y,
      ...(config.options[file].query || {}),
      name: config.selections[index].getSeriesName(input)
    };
    if (filterColumn) {
      param.filter_col = filterColumn;
      param.filter_value = filterValue;
    }
    params.series.push(param);
  });

  return params;
};

const nonNullValuesCondition = (inputs) => {
  if (!inputs) {
    return false;
  }
  const { file, x, y } = inputs[0];
  const knobs = [file, x, y];
  return knobs.every((knob) => !!knob);
};

const updateAxisLabels = (inputs) => {
  if (!inputs) {
    return false;
  }
  const labels = { xCol: inputs[0].x, yCol: inputs[0].y };

  if (inputs.length > 1) {
    labels.x2Col = inputs[1].x;
    labels.y2Col = inputs[1].y;
  }

  return labels;
};

const getColumnOptions = (config, selectedFile) => {
  let options = (selectedFile && config.options[selectedFile] && config.options[selectedFile].columns) || [];
  return options;
};

const noFormatMetrics = (metrics, chart) => {
  return metrics;
};

const noFormatSeries = (series, userInput) => {
  return series;
};

const resolveInitial = (config) => {
  const initialFile = config.options && Object.keys(config.options)[0];
  const columnOptions = getColumnOptions(config, initialFile);

  return config.selections.map((inputRow) => {
    const { ySelection, xSelection, fileSelection, filterSelection } = inputRow;

    return {
      ySelection: { ...ySelection, value: ySelection.value || columnOptions[0] },
      xSelection: { ...xSelection, value: xSelection.value || columnOptions[0] },
      fileSelection: { ...fileSelection, value: fileSelection.value || initialFile },
      filterSelection
    };
  });
};

const resolveSettings = (settings, chart, options) => {
  const dropdownSetting = () => {
    return {
      hidden: false,
      value: null
    };
  };
  const defaultInputSelection = (index) => {
    return {
      ySelection: dropdownSetting(),
      xSelection: dropdownSetting(),
      fileSelection: dropdownSetting(),
      filterSelection: dropdownSetting(),
      getSeriesName: (userInput) => userInput.x,
      title: `Serie ${index}`
    };
  };

  const prepareSelections = (userInput) => {
    return userInput.map((input, index) => {
      const resolved = {
        ...defaultInputSelection(index + 1),
        ...input
      };

      return resolved;
    });
  };

  const defaultSettings = {
    hiddenProperties: ['well', 'DEPTH'],
    seriesFormatter: noFormatSeries,
    selections: [defaultInputSelection(1)],
    metrics: { hidden: false },
    hideAllSelections: false,
    optionsBuilder: createQueryOptions,
    queryResolver: defaultQueryResolver,
    shouldFetch: nonNullValuesCondition,
    updatePlotStyleOnFetch: updateAxisLabels,
    metricsFormatter: noFormatMetrics,
    getFilterOptions: (config) => Object.keys(config.options)
  };

  const resolved = { ...defaultSettings, ...(settings || {}), selections: prepareSelections(settings.selections) };
  resolved.options = resolved.optionsBuilder(chart, resolved);

  return resolved;
};

export {
  resolveSettings,
  resolveInitial,
  noFormatSeries,
  noFormatMetrics,
  getColumnOptions,
  updateAxisLabels,
  defaultQueryResolver,
  createQueryOptions
};
