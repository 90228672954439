import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles, addToCSV } from './HPUtils';

export const HPTableLayersPropTypes = {
  hiddenLayers: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

function populateTable({ hiddenLayers, setRows }) {
  const hiddenLayersArr = hiddenLayers.split(',');

  const options = [];
  hiddenLayersArr.forEach((item, index) => {
    options.push({
      id: `NNItem-${index}`,
      hiddenLayers: item
    });
  });
  setRows(options);
}

function HPTableLayers({ hiddenLayers, onChange }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleChange = (prop, index) => (event) => {
    const { value } = event.target;
    const validationRegex = /^[\d+\:]+$/i;
    const isValid = value === '' || validationRegex.test(value);
    if (!isValid) {
      return;
    }
    onChange('hidden_layers', addToCSV(hiddenLayers, index, value));
  };

  const handleClick = () => {
    const defaultValue = '10:10';
    onChange('hidden_layers', `${hiddenLayers},${defaultValue}`);
  };

  const handleDelete = (index) => () => {
    const list = hiddenLayers.split(',');
    list.splice(index, 1);
    onChange('hidden_layers', list.join(','));
  };

  useEffect(() => {
    populateTable({ hiddenLayers, setRows });
  }, [hiddenLayers, setRows]);

  return (
    <div data-testid="HPTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }}>Hidden Layer Configuration</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell>
                  <TextField
                    value={o.hiddenLayers}
                    placeholder="10:10"
                    onChange={handleChange('hidden_layers', index)}
                  />
                </TableCell>

                <TableCell style={{ width: 20 }}>
                  <IconButton
                    size="small"
                    aria-label="remove"
                    disabled={rows.length <= 1}
                    onClick={handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}

HPTableLayers.propTypes = HPTableLayersPropTypes;

HPTableLayers.defaultProps = {};

export default HPTableLayers;
