import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  grid: {
    width: '100%',
    height: '90%',
    paddingTop: '10px'
  },
  gridToolbar: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginBottom: '10px'
  }
}));

export { useStyles };
