import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative'
  },
  title: {
    marginLeft: theme.spacing(2),
    flex: 1
  },
  wrapper: {
    height: '100%',
    width: '100%',
    position: 'relative'
  }
}));

export { useStyles };
