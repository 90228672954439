import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStyles } from './ImageViewer.styles';
import MuiDialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import CloseIcon from '@material-ui/icons/Close';
import { MapInteractionCSS } from 'react-map-interaction';

export const ImageViewerPropTypes = {
  image: PropTypes.shape({
    url: PropTypes.string,
    title: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

function ImageViewer({ image, onClose }) {
  const classes = useStyles();
  const [value, setValue] = useState({ scale: 1, translation: { x: 0, y: 0 } });
  const handleChange = (value) => {
    setValue(value);
  };

  if (!image) return null;
  return (
    <div className={classes.wrapper}>
      <div style={{ height: '100%' }}>
        <MapInteractionCSS value={value} onChange={handleChange} showControls={true}>
          <img src={image.url} style={{ maxWidth: '80%' }} />
        </MapInteractionCSS>
      </div>
    </div>
  );
}
ImageViewer.propTypes = ImageViewerPropTypes;

ImageViewer.defaultProps = {};

export default ImageViewer;
