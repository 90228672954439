import React, { useState, useContext, createContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLazyChartDataQueryQuery } from 'store/modules/workflows/actions/api';
import { cloneDeep } from 'lodash';
import NodePanel from './NodePanel';

export const NodeSidePanelPropTypes = {
  selectedNode: PropTypes.object.isRequired,
  workflow: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleCancelUpdateNode: PropTypes.func.isRequired,
  handleOpenLog: PropTypes.func.isRequired,
  handleDownloadOutputFiles: PropTypes.func.isRequired,
  handleCloseTab: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired
};
const NodeSidePanelContext = createContext();

export const useNodeSidePanelContext = () => {
  const context = useContext(NodeSidePanelContext);
  return context;
};
const NodeSidePanel = ({
  selectedNode,
  handleCancelUpdateNode,
  handleOpenLog,
  handleDownloadOutputFiles,
  handleCloseTab,
  workflow,
  open,
  onClose
}) => {
  const [fullScreen, setFullScreen] = useState(false);
  const [trigger, result, lastPromiseInfo] = useLazyChartDataQueryQuery();
  const getChartData = async ({ chartId, params }) => {
    const { data } = await trigger({ chartId: chartId, params: params }, true);
    // deep cloning to deal with react plotly mutating state issue:
    //https://github.com/plotly/react-plotly.js/issues/43
    return cloneDeep(data);
  };

  const value = useMemo(() => {
    return {
      selectedNode: selectedNode,
      handleCancelUpdateNode: handleCancelUpdateNode,
      handleOpenLog: handleOpenLog,
      handleDownloadOutputFiles: handleDownloadOutputFiles,
      handleCloseTab: handleCloseTab,
      workflow: workflow,
      open: open,
      onClose: onClose,
      fullScreen: fullScreen,
      setFullScreen: setFullScreen,
      getChartData: getChartData
    };
  }, [selectedNode, workflow, open, fullScreen]);
  return (
    <NodeSidePanelContext.Provider value={value}>
      <NodePanel />
    </NodeSidePanelContext.Provider>
  );
};
NodeSidePanel.propTypes = NodeSidePanelPropTypes;
export default NodeSidePanel;
