import React from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';

export function testRequiredCommaSeparatedInput(value) {
  const exp = /^(-?[0-9]+){1}([,] ?-?[0-9]+)*$/;
  return exp.test(value);
}
export function testOptionalCommaSeparatedInput(value) {
  if (value === '') return true;
  return testRequiredCommaSeparatedInput(value);
}
export const CommaSeparatedPropTypes = {
  children: PropTypes.element,
  value: PropTypes.string.isRequired,
  check: PropTypes.bool
};

const helperText = 'Comma separated input. Ex: 20, 30, 40';
const CommaSeparatedInput = function ({ children, value, check = true, ...rest }) {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <TextField {...rest} value={value} error={check && !testOptionalCommaSeparatedInput(value)} helperText={helperText}>
      {children}
    </TextField>
  );
};

CommaSeparatedInput.propTypes = CommaSeparatedPropTypes;

CommaSeparatedInput.defaultProps = {
  children: <></>,
  check: true
};

export default CommaSeparatedInput;
