import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TooltipTitle from 'components/Common/TooltipTitle';
import Divider from '@material-ui/core/Divider';
import { NODE_TYPES } from 'utils/constants';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Actions from '../../Common/Actions';
import { nodeTypeDefault, Paper } from '../../Styles';

export const QEarthFeatureEngineeringPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  small_reflection: 10,
  large_reflection: 30,
  top_band: 0,
  bottom_band: 0,
  lith_col: 'TOP',
  advanced_parameters: false,
  vert_domain: 'Time',
  nodeType: NODE_TYPES.QEARTH_FEATURE_ENGINEERING
};

function QEarthFeatureEngineering({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeDefault();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  const handleCheckboxChange = (prop) => (event) => {
    const value = event.target.checked;
    setValues({ ...values, [prop]: value });
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="QEarthFeatureEngineering" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <h1> Seismic Sampling Configuration</h1>
      <Paper>
        <TooltipTitle
          tooltip="Length of the shortest cycle (trough to trough) in the zone of interest of your seismic. Provide this value
        in the units and sample rate of your data."
        >
          Length of Smallest Reflection
        </TooltipTitle>
        <TextField
          margin="dense"
          id="small_reflection"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 3, max: 999, step: 1 } }}
          onChange={handleChange('small_reflection')}
          value={values.small_reflection}
        />
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="Length of the longest cycle (trough to trough) in the zone of interest of your seismic. Provide this value
          in the units and sample rate of your data."
        >
          Length of Largest Reflection
        </TooltipTitle>
        <TextField
          margin="dense"
          id="large_reflection"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 3, max: 999, step: 1 } }}
          onChange={handleChange('large_reflection')}
          value={values.large_reflection}
        />
      </Paper>
      <Paper>
        <TooltipTitle tooltip="Select the vertical domain of the seismic data">Vertical Domain</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="vertical-domain-label"
            id="vertical-domain-select"
            type="text"
            value={values.vert_domain}
            placeholder="Select the vertical domain of the seismic data"
            onChange={handleChange('vert_domain')}
          >
            <MenuItem value="Time">Time</MenuItem>
            <MenuItem value="Depth">Depth</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Divider style={{ marginTop: '40px', marginBottom: '40px' }} />
      <h1> Horizon Based Zone Boundaries</h1>
      <Paper>
        <TooltipTitle
          tooltip="Provide distance above or below the shallowest horizon in your dataset. Units are in the domain and sample
        rate of your dataset. Negative values translate your boundary shallower and postive values translate your horizon deeper. For example:
        Providing a -5 in this field for a time-domain dataset with a sample rate of 4ms would create a top boundary for your zone of interest 
        that is 20ms above the shallowest horizon."
        >
          Top Band
        </TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="top_band"
          type="number"
          fullWidth
          onChange={handleChange('top_band')}
          value={values.top_band}
        />
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="Provide distance above or below the deepest horizon in your dataset. Units are in the domain and sample
        rate of your dataset. Negative values translate your boundary shallower and postive values translate your horizon deeper. For example:
        Providing a 5 in this field for a time-domain dataset with a sample rate of 4ms would create a bottom boundary for your zone of interest 
        that is 20ms below the deepest horizon."
        >
          Bottom Band
        </TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="bottom_band"
          type="number"
          fullWidth
          onChange={handleChange('bottom_band')}
          value={values.bottom_band}
        />
      </Paper>
      {values.advanced_parameters && advancedParametersSection({ values, handleChange })}
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

QEarthFeatureEngineering.propTypes = QEarthFeatureEngineeringPropTypes;
QEarthFeatureEngineering.defaultProps = {};

export default QEarthFeatureEngineering;
