import React, { useState } from 'react';
import PropTypes from 'prop-types';
import TextField from '@material-ui/core/TextField';
import TooltipTitle from 'components/Common/TooltipTitle';
import { NODE_TYPES } from 'utils/constants';
import Actions from '../../Common/Actions';
import { nodeTypeDefault, Paper } from '../../Styles';

export const OutputVisualizerPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  nodeType: NODE_TYPES.OUTPUT_VISUALIZER
};

function OutputVisualizer({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeDefault();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="OutputVisualizer" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

OutputVisualizer.propTypes = OutputVisualizerPropTypes;
OutputVisualizer.defaultProps = {};

export default OutputVisualizer;
