// Import the RTK Query methods from the React-specific entry point
import { createApi } from '@reduxjs/toolkit/query/react';
import client from 'utils/workflowClient';
import { tryParseJsonWithNans } from 'components/InteractiveVisualizations/util';
// Define our single API slice object

const axiosBaseQuery =
  () =>
  async ({ url, method, requestData, params }) => {
    try {
      const response = await client({
        url: url,
        method: method,
        data: requestData,
        params: params
      });
      const { data } = response;
      //some working needed to parse numpy nans
      let result;
      //if data is string it's because couldn't be parsed to json and gzip probably because of nans inside
      if (typeof data === 'string') {
        result = tryParseJsonWithNans(data);
      } else result = data;

      return { data: result.data };
    } catch (err) {
      console.error(err);
      return { error: err };
    }
  };
export const apiSlice = createApi({
  // The cache reducer expects to be added at `state.api` (already default - this is optional)
  reducerPath: 'api',
  // All of our requests will have URLs starting with '/fakeApi'
  baseQuery: axiosBaseQuery(),
  // The "endpoints" represent operations and requests for this server
  endpoints: (builder) => ({
    chartDataQuery: builder.query({
      query: (args) => {
        const { chartId, params } = args;
        return {
          method: 'POST',
          url: `/viz/charts/${chartId}/data`,
          requestData: params
        };
      }
    })
  }),
  keepUnusedDataFor: Number.MAX_SAFE_INTEGER
});

export const { useChartDataQueryQuery, useLazyChartDataQueryQuery } = apiSlice;
