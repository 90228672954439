import React from 'react';
import PropTypes from 'prop-types';
import { Paper } from '../../Styles';
import TooltipTitle from 'components/Common/TooltipTitle';
import TextField from '@material-ui/core/TextField';

export const NodeNamePropTypes = {
  value: PropTypes.string.isRequired,
  emptyNodeName: PropTypes.string,
  onChange: PropTypes.func.isRequired
};
const NodeName = ({ onChange, value, emptyNodeName }) => {
  return (
    <>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={onChange}
          value={value}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
    </>
  );
};

NodeName.propTypes = NodeNamePropTypes;

NodeName.defaultProps = {};

export default NodeName;
