import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nodeTypeDefault, Paper } from '../../Styles';
import TextField from '@material-ui/core/TextField';
import Actions from '../../Common/Actions';
import TooltipTitle from 'components/Common/TooltipTitle';
import { NODE_TYPES } from 'utils/constants';

export const ExportSimulationsPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  null_values: '',
  nodeType: NODE_TYPES.EXPORT_SIMULATIONS
};

function ExportSimulations({ data, isCreated = false, onCancel, onSave, onShowLogs, onDownloadOutputFiles }) {
  const classes = nodeTypeDefault();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);

  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="ExportSimulations" className={classes.root}>
      <Paper>
        <TooltipTitle>Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <TooltipTitle tooltip="Value to replace NaNs">Null Values</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="null_values"
          label="Null Values"
          fullWidth
          onChange={handleChange('null_values')}
          value={values.null_values}
          type="number"
          // this error does not work for optional values
          // error={!Number(values.null_values)}
        />
      </Paper>
      <Actions
        isCreated={isCreated}
        onCancel={handleCancel}
        onSave={handleSave}
        onShowLogs={onShowLogs}
        onDownloadOutputFiles={onDownloadOutputFiles}
      />
    </div>
  );
}
ExportSimulations.propTypes = ExportSimulationsPropTypes;

ExportSimulations.defaultProps = {};

export default ExportSimulations;
