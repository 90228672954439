import dynamic from 'next/dynamic';
import React, { useEffect, useMemo, useState } from 'react';
import { useStyles, layoutStyle } from './PlotlyPlot.styles';

const showLegendIcon = {
  width: 500,

  height: 600,

  path: 'M0 96C0 78.3 14.3 64 32 64H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32C14.3 128 0 113.7 0 96zM0 256c0-17.7 14.3-32 32-32H416c17.7 0 32 14.3 32 32s-14.3 32-32 32H32c-17.7 0-32-14.3-32-32zM448 416c0 17.7-14.3 32-32 32H32c-17.7 0-32-14.3-32-32s14.3-32 32-32H416c17.7 0 32 14.3 32 32z'
};
const Plot = dynamic(() => import('react-plotly.js'), { ssr: false });

export default function PlotlyPlot({ style, traces, onRestyle, config, ...rest }) {
  const classes = useStyles();
  const [showLegend, setShowLegend] = useState(style ? style.showlegend : layoutStyle?.showlegend);
  const defaultPlotlyConfig = {
    //toggle button that show/hides legend on plot
    modeBarButtonsToAdd: [
      {
        name: 'Show/hide legend',
        icon: showLegendIcon,
        click: (gd) => {
          setShowLegend(!gd.layout.showlegend);
        }
      }
    ],
    responsive: true
  };

  // saving the plot state allow us to maintain zooming when updating traces or styles
  const [plotState, setPlotState] = useState({
    data: traces,
    layout: style || layoutStyle,
    config: config || defaultPlotlyConfig
  });
  const plotId = { ...rest }.divId || 'plotly-plot';

  useMemo(() => {
    // this is a workaround for issue https://github.com/plotly/plotly.js/issues/6359#issuecomment-1368797995
    const gd = document.getElementById(plotId);
    if (gd) {
      try {
        const fullLayout = gd._fullLayout;
        const scene = fullLayout?.scene?._scene;
        if (scene) {
          const { layout } = scene.graphDiv;
          scene.saveLayout(layout);
        }
      } catch (err) {
        console.log('error saving plotly layout');
        console.error(err);
      }
    }
  }, [plotState]);
  useEffect(() => {
    setPlotState((prevstate) => ({ ...prevstate, data: traces }));
  }, [traces]);
  useEffect(() => {
    if (config) setPlotState((prevstate) => ({ ...prevstate, config: config }));
  }, [config]);
  useEffect(() => {
    setPlotState((prevstate) => ({ ...prevstate, layout: style }));
  }, [style]);

  useEffect(() => {
    setPlotState((prevstate) => ({ ...prevstate, layout: { ...prevstate.layout, showlegend: showLegend } }));
  }, [showLegend]);

  return (
    <Plot
      data={plotState.data}
      layout={plotState.layout}
      className={classes.plot}
      useResizeHandler
      onRestyle={onRestyle}
      config={plotState.config}
      //oninitialized only works well for 3d plots
      // for 2d plots should pass onUpdate={undefined} as parameter
      onInitialized={(figure) => {
        //preserve config, figure does not have config
        setPlotState((prevstate) => ({ ...prevstate, layout: figure.layout, data: figure.data }));
      }}
      //onupdate only works well for 3d plots
      // for 2d plots should pass onUpdate={undefined} as parameter
      onUpdate={(figure) => {
        //preserve config, figure does not have config
        setPlotState((prevstate) => ({ ...prevstate, layout: figure.layout, data: figure.data }));
      }}
      divId="plotly-plot"
      {...rest}
    />
  );
}
