import client from 'utils/workflowClient';

const retrieveConfig = async ({ workflowId, nodeType, configName }) => {
  try {
    const { data = {} } = await client.get(`/workflows/${workflowId}/node_configuration`, {
      params: { node_type: nodeType, config_name: configName }
    });
    const { values = [] } = data;
    return values;
  } catch (err) {
    console.log(err);
    return [];
  }
};

export { retrieveConfig };
export default retrieveConfig;
