import * as types from '../workflows.types';
import client from 'utils/workflowClient';

export const copyNodesAndEdges = ({ workflowId, nodes, edges }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.COPY_NODES_EDGES_REQUEST
    });

    try {
      const {
        list: { workflows = [] }
      } = getState().workflows;

      const changes = {
        position: {
          x: 150,
          y: -150
        },
        suffix: ' - Copy'
      };
      const { data = {} } = await client.post(`/workflows/${workflowId}/duplicate_nodes`, { nodes, edges, changes });
      const { workflow_design: workflow = {} } = data;

      const updatedWorkflows = workflows.map((o) => {
        if (`${o.id}` !== `${workflowId}`) return o;
        return workflow;
      });

      return dispatch({
        type: types.COPY_NODES_EDGES_SUCCESS,
        payload: {
          workflows: updatedWorkflows,
          nodes: workflow.nodes,
          edges: workflow.edges
        }
      });
    } catch (err) {
      return dispatch({
        type: types.COPY_NODES_EDGES_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
