import * as types from '../workflows.types';

export const updateWorkflowStatus = ({ workflowId, executionId, status, createdAt }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_WORKFLOW_STATUS_REQUEST
    });

    let {
      list: { workflows = [] },
      current
    } = getState().workflows;
    const isCurrentWorkflow = workflowId === current?.id;

    const execution = workflows.find((w) => {
      return w.executions.find((o) => o.id === executionId);
    });

    let currentWorkflow = current;
    try {
      if (!execution) return;
      const updatedWorkFlows = workflows.map((o) => {
        if (`${o.id}` !== `${workflowId}`) return o;
        const updated = {
          ...o,
          executions: o.executions.map((p) => {
            if (`${p.id}` !== `${executionId}`) return p;
            return {
              ...p,
              status: {
                ...p.status,
                name: status
              }
            };
          })
        };
        if (isCurrentWorkflow) currentWorkflow = updated;
        return updated;
      });
      return dispatch({
        type: types.UPDATE_WORKFLOW_STATUS_SUCCESS,
        payload: {
          workflows: updatedWorkFlows,
          currentWorkflow
        }
      });
    } catch (err) {
      console.log(err);
      return dispatch({
        type: types.UPDATE_WORKFLOW_STATUS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const updateNodeStatus = ({ workflowId, executionId, nodeId, status: updatedNodeStatus, createdAt }) => {
  return async (dispatch, getState) => {
    dispatch({
      type: types.UPDATE_NODE_STATUS_REQUEST
    });

    try {
      const updatedExecutions = {
        [nodeId]: { status: { name: updatedNodeStatus }, createdAt }
      };

      return dispatch({
        type: types.UPDATE_NODE_STATUS_SUCCESS,
        payload: {
          executions: updatedExecutions
        }
      });
    } catch (err) {
      return dispatch({
        type: types.UPDATE_NODE_STATUS_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
