import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  plot: {
    width: '100%',
    height: '100%',
    padding: theme.spacing(1)
  }
}));

const createLayoutStyle = (overrides, theme) => {
  return {
    title: false,
    autosize: true,
    plot_bgcolor: 'transparent',
    paper_bgcolor: 'transparent',
    font: {
      color: theme?.palette?.chart?.plotly?.textColor || '#e9e9e9',
      size: 13
    },
    tickfont: {
      color: theme?.palette?.chart?.plotly?.textColor || '#e9e9e9'
    },
    xaxis: {
      autorange: true,
      gridcolor: theme?.palette?.chart?.plotly?.gridColor || '#000000',
      color: theme?.palette?.chart?.plotly?.textColor || '#e9e9e9'
    },
    yaxis: {
      autorange: true,
      gridcolor: theme?.palette?.chart?.plotly?.gridColor || '#000000',
      color: theme?.palette?.chart?.plotly?.textColor || '#e9e9e9'
    },
    modebar: {},
    margin: {
      t: 20
    },
    ...overrides
  };
};

const layoutStyle = createLayoutStyle();
export { useStyles, layoutStyle, createLayoutStyle };
