import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles, addToCSV } from '../HyperparameterExplorer/HPUtils';

export const BlindWellsTablePropTypes = {
  blind_wells: PropTypes.string,
  onChange: PropTypes.func.isRequired
};

function populateTable({ blind_wells, setRows }) {
  if (blind_wells === null) {
    setRows([]);
    return;
  }
  const wellsArr = blind_wells.split(',');

  const options = [];
  wellsArr.forEach((item, index) => {
    options.push({
      id: `NNItem-${index}`,
      well_name: item
    });
  });
  setRows(options);
}

function BlindWellsTable({ blind_wells, onChange }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleChange = (prop, index) => (event) => {
    const { value } = event.target;
    onChange('blind_wells', addToCSV(blind_wells, index, value));
  };

  const handleClick = () => {
    const defaultValue = '';
    const newValue = blind_wells === null ? defaultValue : `${blind_wells},${defaultValue}`;
    onChange('blind_wells', newValue);
  };

  const handleDelete = (index) => () => {
    let newValue = null;
    if (index > 0) {
      const wellsArr = blind_wells.split(',');
      wellsArr.splice(index, 1);
      newValue = wellsArr.join(',');
    }
    onChange('blind_wells', newValue);
  };

  useEffect(() => {
    populateTable({ blind_wells, setRows });
  }, [blind_wells, setRows]);

  return (
    <div data-testid="BlindWellsTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell style={{ width: 150 }}>
                  <TextField
                    margin="dense"
                    id={`blind_well-${o.id}`}
                    label="Blind well name"
                    type="text"
                    fullWidth
                    onChange={handleChange('blind_wells', index)}
                    value={o.well_name}
                  />
                </TableCell>

                <TableCell style={{ width: 20 }}>
                  <IconButton size="small" aria-label="remove" onClick={handleDelete(index)}>
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
            {rows.length == 0 && (
              <TableRow>
                <TableCell>No blind wells</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
BlindWellsTable.propTypes = BlindWellsTablePropTypes;

BlindWellsTable.defaultProps = {};

export default BlindWellsTable;
