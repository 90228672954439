import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import HelpIcon from '@material-ui/icons/Help';

export const TooltipTitlePropTypes = {
  children: PropTypes.string.isRequired,
  tooltip: PropTypes.string
};

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center'
  },
  tooltip: {
    marginLeft: theme.spacing(1)
  }
}));

function TooltipTitle({ children, tooltip }) {
  const classes = useStyles();
  return (
    <div data-testid="TooltipTitle" className={classes.root}>
      <Typography>{children}</Typography>
      {tooltip && tooltip.trim() !== '' && (
        <Tooltip title={tooltip} className={classes.tooltip}>
          <HelpIcon />
        </Tooltip>
      )}
    </div>
  );
}

TooltipTitle.propTypes = TooltipTitlePropTypes;

TooltipTitle.defaultProps = {
  tooltip: null
};

export default TooltipTitle;
