import React from 'react';
import cx from 'classnames';
import { nodeStyles } from 'components/ReactFlow/Node/Node.styles';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { nodeTypesDictionary } from 'utils/nodeTypes';
import { rules, inputRules } from 'utils/workflowRules';
import { Styles } from './LegendPanel.styles';

// Get Input and Output Node Types from workflow rules,
// including category to change node background color corresponding
// to the business rules applied to node canvas.

/**
 * Input nodes
 *
 * @param {string} nodeType the type of the node
 * @returns {array} a list of titles and categories
 */
const getInputNodeTypes = (nodeType) => {
  if (!nodeType || !inputRules[nodeType]) {
    return [];
  }
  const inputNodes = inputRules[nodeType]; //

  const inputs = inputNodes.map((type) => {
    const { title } = nodeTypesDictionary[type];
    const { category } = nodeTypesDictionary[type];
    return { title, category };
  });

  return inputs;
};

/**
 * Output nodes
 *
 * @param {string} nodeType the type of the node
 */
const getOutputNodeTypes = (nodeType) => {
  if (!nodeType || !rules[nodeType]) {
    return [];
  }
  const outputNodeTypes = rules[nodeType];
  const outputTypes = Object.keys(outputNodeTypes);

  const outputs = outputTypes.map((type) => {
    if (nodeTypesDictionary[type] === undefined) {
      return [];
    }
    const { title } = nodeTypesDictionary[type];

    const { category } = nodeTypesDictionary[type];
    return { title, category };
  });

  return outputs;
};

/**
 * Get node title and description from the node selected by the user in the node canvas.
 *
 * @param {string} nodeType the type of the node
 */
const getNodeDescription = (nodeType) => {
  if (!nodeType || !nodeTypesDictionary[nodeType].description) {
    return [];
  }
  const { description } = nodeTypesDictionary[nodeType];
  const nodeTitle = nodeTypesDictionary[nodeType].title;
  return { description, nodeTitle };
};

const LegendPanel = ({ selectedNode, inline = false, onCancel }) => {
  const classes = Styles();
  const nodeClasses = nodeStyles();
  const nodeType = selectedNode?.data?.nodeType || '';
  const nodeInputs = getInputNodeTypes(nodeType);
  const nodeOutputs = getOutputNodeTypes(nodeType);
  const nodeDescription = getNodeDescription(nodeType);

  if (!nodeType) {
    return (
      <div data-testid="LegendPanel" className={cx(classes.panel, { [classes.inline]: inline })}>
        <Typography variant="h5" className={classes.title}>
          Select a node
        </Typography>
      </div>
    );
  }

  return (
    <div className={classes.panel} data-testid="LegendPanel">
      <div>
        <Typography variant="h5" gutterBottom color="primary" data-testid="LegendPanel-title">
          {nodeDescription.nodeTitle}
        </Typography>
        <Typography variant="body1" paragraph className={classes.subtitle} data-testid="LegendPanel-description">
          {nodeDescription.description}
        </Typography>
        <Typography variant="h6">Input Node Types</Typography>
        <div className={classes.nodeWrapper}>
          {nodeInputs.map((input) => (
            <div className={cx(classes.nodeDefault, nodeClasses[input.category])} key={input.title}>
              <h4>{input.title}</h4>
            </div>
          ))}
        </div>
        <Typography variant="h6">Output Node Types</Typography>
        <div className={classes.nodeWrapper}>
          {nodeOutputs.map((output) => {
            return (
              <div className={cx(classes.nodeDefault, nodeClasses[output.category])} key={output.title}>
                <h4>{output.title}</h4>
              </div>
            );
          })}
        </div>
        <div className={classes.cancel}>
          <Button color="secondary" onClick={onCancel}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

LegendPanel.propTypes = {};
LegendPanel.defaultProps = {};

export default LegendPanel;
export { getInputNodeTypes, getOutputNodeTypes };
