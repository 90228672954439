import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
  sidePanel: {
    'height': '100%',
    'width': '100%',
    'scrollbarWidth': 'none',
    '-ms-overflow-style': 'none',
    '&::-webkit-scrollbar': {
      display: 'none'
    }
  },
  tabs: {
    'position': '-webkit-sticky',
    'position': 'sticky',
    '& .MuiTab-root': {
      padding: 0
    }
  }
}));

export { useStyles };
