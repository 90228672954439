import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';
import { useStyles, addToCSV } from './HPUtils';

export const HPTableLearningPropTypes = {
  learningRates: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired
};

function populateTable({ learningRates, setRows }) {
  const learningRatesArr = learningRates.split(',');

  const options = [];
  learningRatesArr.forEach((item, index) => {
    options.push({
      id: `NNItem-${index}`,
      learningRates: item
    });
  });
  setRows(options);
}

function HPTableLearning({ learningRates, onChange }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleChange = (prop, index) => (event) => {
    const { value } = event.target;
    onChange('learning_rates', addToCSV(learningRates, index, value));
  };

  const handleClick = () => {
    const defaultValue = '0.001';
    onChange('learning_rates', `${learningRates},${defaultValue}`);
  };

  const handleDelete = (index) => () => {
    const list = learningRates.split(',');
    list.splice(index, 1);
    onChange('learning_rates', list.join(','));
  };

  useEffect(() => {
    populateTable({ learningRates, setRows });
  }, [learningRates, setRows]);

  return (
    <div data-testid="HPTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Learning Rates</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell style={{ width: 150 }}>
                  <TextField
                    value={o.learningRates}
                    type="number"
                    placeholder="0.001"
                    InputProps={{ inputProps: { min: 0.000001, max: 0.1, step: 0.005 } }}
                    onChange={handleChange('learning_rates', index)}
                  />
                </TableCell>

                <TableCell style={{ width: 20 }}>
                  <IconButton
                    size="small"
                    aria-label="remove"
                    disabled={rows.length <= 1}
                    onClick={handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
HPTableLearning.propTypes = HPTableLearningPropTypes;

HPTableLearning.defaultProps = {};

export default HPTableLearning;
