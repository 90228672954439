import React from 'react';
import PropTypes from 'prop-types';
import MuiDialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';

export const DialogPropTypes = {
  open: PropTypes.bool,
  title: PropTypes.string.isRequired,
  children: PropTypes.element,
  body: PropTypes.string,
  confirm: PropTypes.string,
  cancel: PropTypes.string,
  disabled: PropTypes.bool,
  maxWidth: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
};

function Dialog({
  open,
  title,
  children,
  body,
  confirm,
  cancel,
  secondaryConfirm,
  disabled,
  maxWidth,
  onClose,
  onConfirm,
  onCancel,
  onSecondaryConfirm
}) {
  return (
    <MuiDialog
      open={open}
      onClose={onClose}
      maxWidth={maxWidth}
      fullWidth
      aria-labelledby="dialog-title"
      aria-describedby="dialog-description"
    >
      <DialogTitle id="dialog-title">{title}</DialogTitle>
      <DialogContent>
        {body && <DialogContentText id="dialog-description">{body}</DialogContentText>}
        {children && children}
      </DialogContent>
      <DialogActions>
        {onCancel && (
          <Button onClick={onCancel} color="secondary" variant="contained">
            {cancel}
          </Button>
        )}
        {onSecondaryConfirm && (
          <Button onClick={onSecondaryConfirm} color="default" variant="contained" disabled={disabled}>
            {secondaryConfirm}
          </Button>
        )}
        {onConfirm && (
          <Button onClick={onConfirm} color="primary" variant="contained" autoFocus disabled={disabled}>
            {confirm}
          </Button>
        )}
      </DialogActions>
    </MuiDialog>
  );
}
Dialog.propTypes = DialogPropTypes;

Dialog.defaultProps = {
  open: false,
  children: null,
  body: null,
  confirm: 'Confirm',
  cancel: 'Cancel',
  disabled: false,
  maxWidth: 'sm'
};

export default Dialog;
