import { makeStyles } from '@material-ui/core/styles';

export const Styles = makeStyles((theme) => ({
  nodeWrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    padding: theme.spacing(1, 0)
  },
  nodeDefault: {
    padding: '0 20px',
    marginRight: theme.spacing(2),
    marginBottom: theme.spacing(2),
    color: '#ffffff',
    textTransform: 'uppercase',
    borderRadius: '5px',
    letterSpacing: '1px'
  },
  subtitle: {
    paddingBottom: theme.spacing(2)
  },
  panel: {
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(20),
    overflow: 'hidden'
  },
  inline: {
    padding: theme.spacing(0),
    overflow: 'hidden'
  },
  cancel: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));
