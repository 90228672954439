import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { nodeTypeHPE, Paper } from '../../Styles';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Select from '@material-ui/core/Select';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Actions from '../../Common/Actions';
import TooltipTitle from 'components/Common/TooltipTitle';
import HPTableActivations from './HPTableActivations';
import HPTableLearning from './HPTableLearning';
import HPTableRegularization from './HPTableRegularization';
import HPTableLayers from './HPTableLayers';
import { NODE_TYPES } from 'utils/constants';

export const HyperparameterExplorerPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  seed: '42',

  advance_training_options: false,
  advance_validation_parameters: false,
  advanced_explorer_parameters: false,

  early_stop_patience: 80,
  validation_split: 30,
  batch_size: 512,
  epochs: 1500,
  reducelr_patience: 40,
  reducelr_factor: 0.7,

  hidden_layers: '10:10',
  regularization_methods: 'l2',
  regularization_params: '0.00001',
  learning_rates: '0.001',
  activations: 'relu',

  top_statistics: 0,
  n_splits: 3,

  nodeType: NODE_TYPES.HYPER_PARAMETER_EXPLORER
};

function HyperparameterExplorer({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = nodeTypeHPE();
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;

  const handleChange = (prop) => (event) => {
    let { value } = event.target;

    if (prop === 'validation_split') {
      value = parseInt(`${value}`.replace(/[^0-9]/g, ''));
    }

    setValues({ ...values, [prop]: value });
  };

  const handleCheckboxChange = (prop) => (event) => {
    const value = event.target.checked;
    setValues({ ...values, [prop]: value });
  };

  const handleTableChange = (prop, value) => {
    setValues((prev) => ({ ...prev, [prop]: value }));
  };

  const handleSave = () => {
    setSaveNode(true);
    if (values.name !== '') {
      onSave({ ...values });
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues(data);
    }
  }, []);

  return (
    <div data-testid="HyperparameterExplorer" className={classes.root}>
      <Paper>
        <TooltipTitle tooltip="Friendly name">Node Name</TooltipTitle>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Node Name"
          fullWidth
          onChange={handleChange('name')}
          value={values.name}
          error={!!emptyNodeName}
          helperText={emptyNodeName}
        />
      </Paper>
      <Paper>
        <HPTableLayers hiddenLayers={values.hidden_layers} onChange={handleTableChange} />
      </Paper>
      <Paper>
        <HPTableRegularization
          regularizationMethods={values.regularization_methods}
          regularizationParams={values.regularization_params}
          onChange={handleTableChange}
        />
      </Paper>
      <Paper>
        <HPTableLearning learningRates={values.learning_rates} onChange={handleTableChange} />
      </Paper>
      <Paper>
        <HPTableActivations activations={values.activations} onChange={handleTableChange} />
      </Paper>

      <FormControlLabel
        style={{ width: 160, padding: '20px' }}
        control={
          <Checkbox
            checked={values.advance_training_options}
            value={values.advance_training_options}
            onChange={handleCheckboxChange('advance_training_options')}
            name="advance_training_options"
          />
        }
        label="Show Advanced Training Options"
      />
      {values.advance_training_options && advancedTrainingConfigurationSection({ values, handleChange })}

      <FormControlLabel
        style={{ width: 190, padding: '20px' }}
        control={
          <Checkbox
            checked={values.advance_validation_parameters}
            value={values.advance_validation_parameters}
            onChange={handleCheckboxChange('advance_validation_parameters')}
            name="advance_validation_parameters"
          />
        }
        label="Show Advanced Validation Parameters"
      />
      {values.advance_validation_parameters && advancedValidationParametersSection({ values, handleChange })}

      <FormControlLabel
        style={{ width: 190, padding: '20px' }}
        control={
          <Checkbox
            checked={values.advanced_explorer_parameters}
            value={values.advanced_explorer_parameters}
            onChange={handleCheckboxChange('advanced_explorer_parameters')}
            name="advanced_explorer_parameters"
          />
        }
        label="Show Advanced Explorer Parameters"
      />
      {values.advanced_explorer_parameters && advancedExplorerParameters({ values, handleChange })}

      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

function advancedTrainingConfigurationSection({ values, handleChange }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Number of Training Epochs</TooltipTitle>
        <TextField
          margin="dense"
          id="epochs"
          type="number"
          fullWidth
          onChange={handleChange('epochs')}
          value={values.epochs}
          InputProps={{ inputProps: { min: 100, max: 2000, step: 50 } }}
        />
      </Paper>

      <Paper>
        <TooltipTitle>Training Batch Size</TooltipTitle>
        <TextField
          margin="dense"
          id="batch_size"
          type="number"
          fullWidth
          onChange={handleChange('batch_size')}
          value={values.batch_size}
          InputProps={{ inputProps: { min: 128, max: 2048, step: 128 } }}
        />
      </Paper>

      <Paper>
        <TooltipTitle>Learning Rate Schedule Patience</TooltipTitle>
        <TextField
          margin="dense"
          id="reducelr_patience"
          type="number"
          fullWidth
          onChange={handleChange('reducelr_patience')}
          value={values.reducelr_patience}
          InputProps={{ inputProps: { min: 10, max: 100, step: 5 } }}
        />
      </Paper>

      <Paper>
        <TooltipTitle>Learning Rate Schedule Reduction</TooltipTitle>
        <TextField
          margin="dense"
          id="reducelr_factor"
          type="number"
          fullWidth
          onChange={handleChange('reducelr_factor')}
          value={values.reducelr_factor}
          InputProps={{ inputProps: { min: 0.1, max: 0.9, step: 0.1 } }}
        />
      </Paper>
    </div>
  );
}

function advancedValidationParametersSection({ values, handleChange }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Validation Split</TooltipTitle>
        <TextField
          margin="dense"
          id="validation_split"
          type="number"
          fullWidth
          onChange={handleChange('validation_split')}
          value={values.validation_split}
          InputProps={{ inputProps: { min: 10, max: 90, step: 1 } }}
        />
      </Paper>

      <Paper>
        <TooltipTitle>Early Stop Patience</TooltipTitle>
        <TextField
          margin="dense"
          id="early_stop_patience"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 10, max: 100, step: 5 } }}
          onChange={handleChange('early_stop_patience')}
          value={values.early_stop_patience}
        />
      </Paper>
    </div>
  );
}

function advancedExplorerParameters({ values, handleChange }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Cross Validation Folds</TooltipTitle>
        <TextField
          margin="dense"
          id="n_splits"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 1, max: 20, step: 1 } }}
          onChange={handleChange('n_splits')}
          value={values.n_splits}
          helperText="Required: Number of folds must be equal to or less than the total number of wells in the workflow"
        />
      </Paper>
      <Paper>
        <TooltipTitle>Seed</TooltipTitle>
        <FormControl fullWidth>
          <Select labelId="type-select-label" id="type-select" value={values.seed} onChange={handleChange('seed')}>
            <MenuItem value="42">Seed 1</MenuItem>
            <MenuItem value="69420">Seed 2</MenuItem>
            <MenuItem value="80085">Seed 3</MenuItem>
            <MenuItem value="none">Random</MenuItem>
          </Select>
        </FormControl>
      </Paper>

      <Paper>
        <TooltipTitle>Number of Top Performers to Plot</TooltipTitle>
        <TextField
          margin="dense"
          id="top_statistics"
          type="number"
          fullWidth
          InputProps={{ inputProps: { min: 3, max: 53, step: 5 } }}
          onChange={handleChange('top_statistics')}
          value={values.top_statistics}
        />
      </Paper>
    </div>
  );
}
HyperparameterExplorer.propTypes = HyperparameterExplorerPropTypes;
HyperparameterExplorer.defaultProps = {};

export default HyperparameterExplorer;
