import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    marginBottom: theme.spacing(5),
    padding: theme.spacing(2)
  },
  inline: {
    paddingTop: theme.spacing(2)
  },
  header: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(2)
  },
  nodeTitle: {
    display: 'flex'
  },
  title: {
    marginBottom: theme.spacing(2),
    flex: 1
  },
  nodeId: {
    color: '#bdbdbd'
  }
}));

export { useStyles };
