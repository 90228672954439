import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import IconButton from '@material-ui/core/IconButton';
import AddIcon from '@material-ui/icons/Add';
import DeleteIcon from '@material-ui/icons/Delete';

export const NNTablePropTypes = {
  layerSizes: PropTypes.string.isRequired,
  activations: PropTypes.string.isRequired,
  initializers: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired
};

const useStyles = makeStyles((theme) => ({
  root: {},
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1)
  }
}));

const updateCSV = (name, prop, index, value, onChange) => {
  const list = prop.split(',');
  list[index] = value;
  onChange(name, list.join(','));
};

const defaultInitializerValue = (value) => {
  let defaultValue = '';

  if (value == 'relu') {
    defaultValue = 'random_uniform';
  } else if (['tanh', 'sigmoid', 'selu', 'elu'].includes(value)) {
    defaultValue = 'glorot_normal';
  }
  return defaultValue;
};

function NNTable({ layerSizes, activations, initializers, onChange, onDelete }) {
  const classes = useStyles();
  const [rows, setRows] = useState([]);

  const handleChange = (prop, index) => (event) => {
    const { value } = event.target;
    switch (prop) {
      case 'size':
        updateCSV('layer_sizes', layerSizes, index, value, onChange);
        break;
      case 'activation':
        updateCSV('activations', activations, index, value, onChange);
        const kernelValue = defaultInitializerValue(value);
        updateCSV('kernel_initializers', initializers, index, kernelValue, onChange);
        break;
      case 'initializer':
        updateCSV('kernel_initializers', initializers, index, value, onChange);
        break;
      default:
        break;
    }
  };

  const handleClick = () => {
    onChange('layer_sizes', `${layerSizes},`);
    onChange('activations', `${activations},`);
    onChange('kernel_initializers', `${initializers},`);
  };

  const handleDelete = (index) => () => {
    onDelete(index);
  };

  useEffect(() => {
    const layerSizesArr = layerSizes.split(',');
    const activationsArr = activations.split(',');
    const initializersArr = initializers.split(',');
    const options = [];
    layerSizesArr.forEach((item, index) => {
      options.push({
        id: `NNItem-${index}`,
        size: item,
        activation: activationsArr[index] || '',
        initializer: initializersArr[index] || ''
      });
    });
    setRows(options);
  }, [layerSizes, activations, initializers]);

  return (
    <div data-testid="NNTable" className={classes.root}>
      <TableContainer component="div">
        <Table className={classes.table} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell style={{ width: 100 }}>Layer</TableCell>
              <TableCell>Activation</TableCell>
              <TableCell>Initializer</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((o, index) => (
              <TableRow key={o.id}>
                <TableCell>
                  <TextField value={o.size} type="number" onChange={handleChange('size', index)} />
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  <Select
                    value={o.activation}
                    fullWidth
                    labelId="activation-select-label"
                    id="activation-select"
                    onChange={handleChange('activation', index)}
                  >
                    <MenuItem value="relu">ReLU</MenuItem>
                    <MenuItem value="sigmoid">Sigmoid</MenuItem>
                    <MenuItem value="softmax">Softmax</MenuItem>
                    <MenuItem value="softplus">Softplus</MenuItem>
                    <MenuItem value="softsign">Softsign</MenuItem>
                    <MenuItem value="tanh">Tanh</MenuItem>
                    <MenuItem value="selu">SeLU</MenuItem>
                    <MenuItem value="elu">eLU</MenuItem>
                    <MenuItem value="exponential">Exponential</MenuItem>
                    <MenuItem value="linear">Linear</MenuItem>
                  </Select>
                </TableCell>
                <TableCell style={{ width: 150 }}>
                  <Select
                    value={o.initializer}
                    fullWidth
                    labelId="initializer-select-label"
                    id="initializer-select"
                    onChange={handleChange('initializer', index)}
                  >
                    <MenuItem value="random_normal">Random Normal</MenuItem>
                    <MenuItem value="random_uniform">Random Uniform</MenuItem>
                    <MenuItem value="truncated_normal">Truncated Normal</MenuItem>
                    <MenuItem value="zeroes">Zeroes</MenuItem>
                    <MenuItem value="ones">Ones</MenuItem>
                    <MenuItem value="glorot_normal">Glorot Normal</MenuItem>
                    <MenuItem value="glorot_uniform">Glorot Uniform</MenuItem>
                    <MenuItem value="identity">Identity</MenuItem>
                    <MenuItem value="orthogonal">Orthogonal</MenuItem>
                    <MenuItem value="constant">Constant</MenuItem>
                  </Select>
                </TableCell>
                <TableCell style={{ width: 20 }}>
                  <IconButton
                    size="small"
                    aria-label="remove"
                    disabled={rows.length <= 1}
                    onClick={handleDelete(index)}
                  >
                    <DeleteIcon />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div className={classes.actions}>
        <IconButton aria-label="add" onClick={handleClick}>
          <AddIcon />
        </IconButton>
      </div>
    </div>
  );
}
NNTable.propTypes = NNTablePropTypes;

NNTable.defaultProps = {};

export default NNTable;
