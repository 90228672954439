import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';

import Card from '@material-ui/core/Card';
import CardHeader from '@material-ui/core/CardHeader';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardMedia from '@material-ui/core/CardMedia';
import TableChart from '@material-ui/icons/TableChart';
import { useSelector } from 'react-redux';
import ChartLoader, { chartMap } from 'components/InteractiveVisualizations/ChartLoader';
import chartDescriptions from 'components/InteractiveVisualizations/chartDescriptions';
import Dialog from 'components/Common/Dialog';
import DataGridViewer from '../DataGridViewer';
import ImageViewer from '../ImageViewer';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: 'calc(100vh - 50px)',
    width: '100%',
    height: '100%',
    overflowY: 'auto',
    background: theme.palette.background
  },
  title: {
    display: 'flex',
    padding: theme.spacing(1),
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(2)
  },
  tooltipContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginLeft: theme.spacing(2)
  },
  chartWrapper: {
    height: '100%',
    width: '100%',
    display: 'flex',
    minHeight: '95vh',
    flex: 1,
    flexDirection: 'column'
  },
  cardsContainer: {
    width: '100%',
    display: 'flex',
    padding: theme.spacing(3),
    gap: theme.spacing(2),
    flexWrap: 'wrap',
    justifyContent: 'center'
  },
  card: {
    width: 345,
    backgroundColor: theme.palette.background.paper
  }
}));

function isSupportedVisualizationFile(fileName) {
  return !fileName.endsWith('.html') && !fileName.endsWith('.json') && !fileName.endsWith('.zip');
}
function hasChartIviz(charts) {
  if (!charts.length) return false;
  return !!chartMap[charts[0].nodeType];
}
export const InteractiveVisualizationsPropTypes = {
  staticVisualizations: PropTypes.array.isRequired,
  charts: PropTypes.array.isRequired
};

const InteractiveVisualizations = ({ staticVisualizations, charts }) => {
  const classes = useStyles();
  const [selectedVisFile, setSelectedVisFile] = useState(null);

  const handleClickVisFile = (file) => () => {
    setSelectedVisFile(file);
  };

  return (
    <div className={classes.root}>
      {/* {loadingChart && <LoadingChart />} */}
      {hasChartIviz(charts) && <div className={classes.chartWrapper}>{ChartLoader(charts[0])}</div>}
      <div className={classes.cardsContainer}>
        {staticVisualizations
          .filter((x) => isSupportedVisualizationFile(x.name))
          .map((tile, index) => (
            <Card className={classes.card} key={`vis-card-${index}`}>
              <CardActionArea
                onClick={handleClickVisFile({
                  title: tile.name,
                  url: tile.url,
                  type: tile.url.includes('.csv') ? 'grid' : 'image'
                })}
              >
                {!tile.url.includes('.csv') ? (
                  <CardMedia image={tile.url} title={tile.name} height="100" component="img" />
                ) : (
                  <div className={classes.csv}>
                    <TableChart className={classes.icon} />
                  </div>
                )}
                <CardHeader title={tile.name} />
              </CardActionArea>
            </Card>
          ))}
      </div>

      <Dialog open={!!selectedVisFile} onClose={(e) => setSelectedVisFile(null)} maxWidth="lg">
        <div style={{ width: '100%', height: '80vh' }}>
          {!!selectedVisFile && selectedVisFile?.type === 'grid' ? (
            <DataGridViewer file={selectedVisFile} onClose={(e) => setSelectedVisFile(null)} />
          ) : (
            <ImageViewer image={selectedVisFile} onClose={(e) => setSelectedVisFile(null)} />
          )}
        </div>
      </Dialog>
    </div>
  );
};

InteractiveVisualizations.propTypes = InteractiveVisualizationsPropTypes;
export default InteractiveVisualizations;
