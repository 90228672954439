import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';

export const ActionsPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired,
  onShowLogs: PropTypes.func,
  onDownloadOutputFiles: PropTypes.func
};

const useStyles = makeStyles((theme) => ({
  actions: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    marginBottom: '60px'
  },
  flex: {
    flex: 1
  }
}));

function Actions({ isCreated = false, onCancel, onSave, onShowLogs, onDownloadOutputFiles }) {
  const classes = useStyles();

  return (
    <div className={classes.actions}>
      {isCreated && onShowLogs && (
        <Button color="primary" onClick={onShowLogs}>
          logs
        </Button>
      )}
      {isCreated && onDownloadOutputFiles && (
        <Button color="primary" onClick={onDownloadOutputFiles}>
          Download outputs
        </Button>
      )}
      <div className={classes.flex} />
      <Button color="secondary" onClick={onCancel}>
        cancel
      </Button>
      <Button color="primary" variant="contained" onClick={onSave}>
        {isCreated ? 'update' : 'save'}
      </Button>
    </div>
  );
}
Actions.propTypes = ActionsPropTypes;

Actions.defaultProps = {};

export default Actions;
