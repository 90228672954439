import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { NODE_TYPES } from 'utils/constants';
import TextField from '@material-ui/core/TextField';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Slider from '@material-ui/core/Slider';
import Divider from '@material-ui/core/Divider';
import TooltipTitle from 'components/Common/TooltipTitle';
import client from 'utils/workflowClient';
import { makeStyles } from '@material-ui/core/styles';
import { testOptionalIntegerInput, testRequiredFloatInput } from '../../Common/Components/utils';
import NodeName from '../../Common/Components/NodeName';
import NNTable from './NNTable';
import HPTableRegularization from '../HyperparameterExplorer/HPTableRegularization';
import BlindWellsTable from './BlindWellsTable';
import Actions from '../../Common/Actions';
import { nodeTypeScroll, Paper } from '../../Styles';

export const ModelTrainingPropTypes = {
  isCreated: PropTypes.bool,
  onCancel: PropTypes.func.isRequired,
  onSave: PropTypes.func.isRequired
};

const initialValues = {
  name: '',
  blind_wells: null,
  scaling_method: 'MinMax',
  layer_sizes: '',
  activations: '',
  kernel_initializers: '',
  learning_rate: '0.005',
  random_seed: randomUint32(),
  random_seed_option: false,
  advanced_parameters: false,
  optimizer: 'Adam',
  regularization_method: 'none',
  regularization_param: '.0000001',
  early_stop_patience: 60,
  reducelr_factor: 0.6,
  reducelr_patience: 30,
  advanced_learning_rate: false,
  batch_size: 256,
  epochs: 5000,
  validation_split: 0.5,
  nodeType: NODE_TYPES.MODEL_TRAINING,
  experiment_name: '',
  load_mlflow_exp: false
};
const styles = makeStyles((theme) => ({
  checkbox: {
    padding: theme.spacing(2),
    fontSize: '14px',
    width: '50%'
  }
}));

function ModelTraining({ data, isCreated = false, onCancel, onSave, onShowLogs }) {
  const classes = { ...nodeTypeScroll(), ...styles() };
  const [values, setValues] = useState(initialValues);
  const [saveNode, setSaveNode] = useState(false);
  const [mlflowExperiments, setMlflowExperiments] = useState([]);
  const emptyNodeName = saveNode && !values.name ? 'Node name is required' : null;
  const invalidFloatValue = (value) => saveNode && (!testRequiredFloatInput(value) ? 'Invalid float number' : null);

  const validateValues = () => {
    return (
      !!values.name &&
      testRequiredFloatInput(values.learning_rate) &&
      testRequiredFloatInput(values.regularization_param) &&
      testRequiredFloatInput(values.reducelr_factor) &&
      testRequiredFloatInput(values.validation_split)
    );
  };
  const handleChange = (prop) => (event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const handleChangeInteger = (prop) => (event) => {
    if (testOptionalIntegerInput(event.target.value)) {
      return setValues({ ...values, [prop]: Number(event.target.value) });
    }
  };

  const handleValueChange = (prop, value) => {
    setValues((prev) => ({ ...prev, [prop]: value }));
  };

  const handleSliderChange = (prop) => (event, newValue) => {
    setValues({ ...values, [prop]: newValue });
  };

  const handleCheckboxChange = (prop) => (event) => {
    const value = event.target.checked;
    setValues({ ...values, [prop]: value });
  };

  const handleTableChange = (prop, value) => {
    let propName = prop;
    propName = propName === 'regularization_methods' ? 'regularization_method' : propName;
    propName = propName === 'regularization_params' ? 'regularization_param' : propName;

    setValues((prev) => ({ ...prev, [propName]: value }));
  };

  const handleDeleteNN = (index) => {
    const { layer_sizes, activations, kernel_initializers } = values;
    const layerSizesArr = layer_sizes.split(',');
    const activationsArr = activations.split(',');
    const initializersArr = kernel_initializers.split(',');
    layerSizesArr.splice(index, 1);
    activationsArr.splice(index, 1);
    initializersArr.splice(index, 1);
    setValues((prev) => ({
      ...prev,
      layer_sizes: layerSizesArr.join(','),
      activations: activationsArr.join(','),
      kernel_initializers: initializersArr.join(',')
    }));
  };

  const handleSave = () => {
    setSaveNode(true);
    if (validateValues()) {
      onSave(values);
      setSaveNode(false);
    }
  };

  const handleCancel = () => {
    setValues(initialValues);
    onCancel();
  };

  React.useEffect(() => {
    if (data) {
      setValues({ ...initialValues, ...data });
    } else {
      handleTableChange('random_seed', randomUint32());
    }
  }, [data]);

  React.useEffect(() => {
    async function getMlFlowExperiments() {
      try {
        const response = await client.get('mlflow/experiments');
        setMlflowExperiments(response.data.experiments);
      } catch (err) {
        console.error(err);
      }
    }
    getMlFlowExperiments();
  }, []);

  return (
    <div data-testid="ModelTraining" className={classes.root}>
      <NodeName onChange={handleChange('name')} value={values.name} emptyNodeName={emptyNodeName} />

      {/* Not being used for now*/}
      {/* <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox
            checked={values.load_mlflow_exp}
            value={values.load_mlflow_exp}
            onChange={handleCheckboxChange('load_mlflow_exp')}
            name="load_mlflow_exp"
          />
        }
        label="Load MLFlow Experiment"
      /> */}
      {/* <Paper>
        {values.load_mlflow_exp ? (
          <>
            <TooltipTitle tooltip="Name of the mlflow experiment to be loaded">Experiment name</TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="experiment_name-select-label"
                id="experiment_name-select"
                onChange={handleChange('experiment_name')}
                value={values.experiment_name}
              >
                {mlflowExperiments.map((exp, index) => (
                  <MenuItem value={exp} key={index}>
                    {exp}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </>
        ) : (
          <>
            <TooltipTitle tooltip="Name of the experiment to be loaded or saved">Experiment name</TooltipTitle>
            <TextField
              margin="dense"
              id="experiment_name"
              type="text"
              fullWidth
              value={values.experiment_name}
              onChange={handleChange('experiment_name')}
            />
          </>
        )}
      </Paper> */}

      {/* {!values.load_mlflow_exp && ( */}
      <>
        <Paper>
          <TooltipTitle tooltip="Well(s) witheld from the model for internal blind testing.">Blind Wells</TooltipTitle>
          <BlindWellsTable blind_wells={values.blind_wells} onChange={handleTableChange} />
        </Paper>
        <Paper>
          <TooltipTitle>Hidden Layer Configuration</TooltipTitle>
          <NNTable
            layerSizes={values.layer_sizes}
            activations={values.activations}
            initializers={values.kernel_initializers}
            onChange={handleValueChange}
            onDelete={handleDeleteNN}
          />
        </Paper>
        <Paper>
          <HPTableRegularization
            regularizationMethods={values.regularization_method}
            regularizationParams={values.regularization_param}
            onChange={handleTableChange}
            multipleRows={false}
          />
        </Paper>
        <Paper>
          <TooltipTitle>Learning Rate</TooltipTitle>
          <TextField
            margin="dense"
            id="learning_rate"
            label="Learning Rate"
            type="text"
            fullWidth
            error={invalidFloatValue(values.learning_rate)}
            onChange={handleChange('learning_rate')}
            value={values.learning_rate}
          />
        </Paper>
        <Paper>
          <TooltipTitle
            tooltip="Number of epochs elapsed without improvement to validation performance before the model
        automatically terminantes training process."
          >
            Early Stopping Patience
          </TooltipTitle>
          <TextField
            margin="dense"
            id="early_stop_patience"
            label="Early Stopping Patience"
            type="text"
            fullWidth
            onChange={handleChangeInteger('early_stop_patience')}
            value={values.early_stop_patience}
          />
        </Paper>
        <Paper>
          <TooltipTitle
            tooltip="Percentage of training witheld for validation. Early stopping patience monitors the performance 
        on this data, not the remaining training data."
          >
            Validation Split
          </TooltipTitle>
          <TextField
            margin="dense"
            id="validation_split"
            label="Validation Split"
            type="text"
            fullWidth
            error={invalidFloatValue(values.validation_split)}
            onChange={handleChange('validation_split')}
            value={values.validation_split}
          />
        </Paper>
        <Divider style={{ marginTop: '40px', marginBottom: '40px' }} />
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={values.random_seed_option}
              value={values.random_seed_option}
              onChange={handleCheckboxChange('random_seed_option')}
              name="random_seed_option"
            />
          }
          label="Specify Random Seed"
        />
        {values.random_seed_option && (
          <Paper>
            <TooltipTitle
              tooltip="Setting a the random number generator (RNG) seed helps anchor the random state of alrorithms leading.
      to more predictable results and reproducibility."
            >
              Select Random Seed
            </TooltipTitle>
            <FormControl fullWidth>
              <Select
                labelId="random-seed-label"
                id="random-seed-select"
                value={values.random_seed}
                onChange={handleChange('random_seed')}
              >
                <MenuItem value="0">0</MenuItem>
                <MenuItem value="1">1</MenuItem>
                <MenuItem value="2">2</MenuItem>
                <MenuItem value="3">3</MenuItem>
              </Select>
            </FormControl>
          </Paper>
        )}
        <FormControlLabel
          style={{ width: 185, padding: '20px' }}
          control={
            <Checkbox
              checked={values.advanced_parameters}
              value={values.advanced_parameters}
              onChange={handleCheckboxChange('advanced_parameters')}
              name="advanced_parameters"
            />
          }
          label="Configure Advanced Parameters"
        />
        {values.advanced_parameters && advancedParametersSection({ values, handleChange, handleChangeInteger })}
        <FormControlLabel
          className={classes.checkbox}
          control={
            <Checkbox
              checked={values.advanced_learning_rate}
              value={values.advanced_learning_rate}
              onChange={handleCheckboxChange('advanced_learning_rate')}
              name="advanced_learning_rate"
            />
          }
          label="Configure Advanced Learning Rate Parameters"
        />
        {values.advanced_learning_rate && advancedLearningRate({ values, handleSliderChange, handleChangeInteger })}
      </>
      {/* )} */}

      <Actions isCreated={isCreated} onCancel={handleCancel} onSave={handleSave} onShowLogs={onShowLogs} />
    </div>
  );
}

function advancedParametersSection({ values, handleChange, handleChangeInteger }) {
  return (
    <div>
      <Paper>
        <TooltipTitle>Scaling</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="scaling_method-select-label"
            id="scaling_method-select"
            onChange={handleChange('scaling_method')}
            value={values.scaling_method}
          >
            <MenuItem value="MinMax">MinMax</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle>Optimizer</TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="optimizer-select-label"
            id="optimizer-select"
            onChange={handleChange('optimizer')}
            value={values.optimizer}
          >
            <MenuItem value="SGD">SGD</MenuItem>
            <MenuItem value="RMSprop">RMSprop</MenuItem>
            <MenuItem value="Adam">Adam</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle
          tooltip="The number of samples fed through the neural network before backpropagation is performed and the
      network's parameters are updated."
        >
          Batch Size
        </TooltipTitle>
        <FormControl fullWidth>
          <Select
            labelId="batch-size-label"
            id="batch-size-select"
            value={values.batch_size}
            onChange={handleChange('batch_size')}
          >
            <MenuItem value="64">64</MenuItem>
            <MenuItem value="128">128</MenuItem>
            <MenuItem value="256">256</MenuItem>
            <MenuItem value="512">512</MenuItem>
          </Select>
        </FormControl>
      </Paper>
      <Paper>
        <TooltipTitle>Epochs</TooltipTitle>
        <TextField
          margin="dense"
          id="epochs"
          label="Epochs"
          type="text"
          fullWidth
          onChange={handleChangeInteger('epochs')}
          value={values.epochs}
        />
      </Paper>
    </div>
  );
}

function advancedLearningRate({ values, handleSliderChange, handleChangeInteger }) {
  return (
    <div>
      <Paper>
        <TooltipTitle tooltip="Number of epochs that must elapse before the learning rate is reduced.">
          Decay Patience
        </TooltipTitle>
        <TextField
          margin="dense"
          id="reducelr_patience"
          label="Decay Patience"
          type="text"
          fullWidth
          onChange={handleChangeInteger('reducelr_patience')}
          value={values.reducelr_patience}
        />
      </Paper>
      <Paper>
        <TooltipTitle tooltip="A factor that defines the rate at which learning rate is reduced after the Decay Patience has been met.">
          Decay Factor
        </TooltipTitle>
        <Slider
          value={values.reducelr_factor}
          onChange={handleSliderChange('reducelr_factor')}
          min={0}
          max={1}
          step={0.05}
          valueLabelDisplay="auto"
          aria-labelledby="range-slider"
        />
      </Paper>
    </div>
  );
}

ModelTraining.propTypes = ModelTrainingPropTypes;
ModelTraining.defaultProps = {};

export default ModelTraining;

function randomUint32() {
  return Math.floor(Math.random() * Math.pow(2, 32));
}
