import axios from 'axios';
import * as types from '../workflows.types';
import client from 'utils/workflowClient';
import { downloadResource } from 'store/modules/resources/resources.actions';

export const downloadOutputFiles = ({ nodeId, executionId, suggestedFilename }) => {
  return async (dispatch) => {
    dispatch({
      type: types.DOWNLOAD_NODE_OUTPUT_REQUEST
    });

    if (!nodeId || !executionId) {
      return dispatch({
        type: types.DOWNLOAD_NODE_OUTPUT_SUCCESS,
        payload: {
          outputFiles: []
        }
      });
    }

    try {
      const uuid = `${nodeId}_${executionId}`;
      const { data } = await client.get(`/nodes/${uuid}/output_files`);
      const { files = [] } = data;

      if (files) {
        const { location } = files[0];
        const filename = `${executionId}_${suggestedFilename}`;
        dispatch(downloadResource({ location, downloadCustomFilename: filename }));
      }

      return dispatch({
        type: types.DOWNLOAD_NODE_OUTPUT_SUCCESS,
        payload: {
          files
        }
      });
    } catch (err) {
      return dispatch({
        type: types.DOWNLOAD_NODE_OUTPUT_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};

export const listOutputFiles = ({ nodeId, executionId }) => {
  return async (dispatch) => {
    dispatch({
      type: types.LIST_NODE_OUTPUT_REQUEST
    });

    if (!nodeId || !executionId) {
      return dispatch({
        type: types.LIST_NODE_OUTPUT_SUCCESS,
        payload: {
          outputFiles: []
        }
      });
    }

    try {
      const uuid = `${nodeId}_${executionId}`;
      const { data } = await client.get(`/nodes/${uuid}/output_files`);
      const { files = [] } = data;
      return dispatch({
        type: types.LIST_NODE_OUTPUT_SUCCESS,
        payload: {
          outputFiles: files
        }
      });
    } catch (err) {
      return dispatch({
        type: types.LIST_NODE_OUTPUT_FAILURE,
        payload: {
          error: err.response.data.error || err.message
        }
      });
    }
  };
};
